import React from "react";

const ArrowSvg = ({size}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2289_8985)">
                <path d="M19.1463 28.2907C19.2294 28.2911 19.3118 28.2752 19.3887 28.2438C19.4656 28.2124 19.5355 28.1661 19.5945 28.1076C19.6537 28.0489 19.7007 27.9791 19.7327 27.9022C19.7648 27.8253 19.7812 27.7428 19.7812 27.6594C19.7812 27.5761 19.7648 27.4936 19.7327 27.4167C19.7007 27.3397 19.6537 27.2699 19.5945 27.2112L14.3552 21.9782L19.5945 16.7451C19.7134 16.6262 19.7802 16.465 19.7802 16.2969C19.7802 16.1288 19.7134 15.9676 19.5945 15.8487C19.4757 15.7299 19.3144 15.6631 19.1463 15.6631C18.9782 15.6631 18.817 15.7299 18.6982 15.8487L13.0169 21.53C12.9577 21.5887 12.9108 21.6585 12.8787 21.7354C12.8467 21.8123 12.8302 21.8948 12.8302 21.9782C12.8302 22.0615 12.8467 22.144 12.8787 22.2209C12.9108 22.2979 12.9577 22.3677 13.0169 22.4264L18.6982 28.1076C18.7571 28.1661 18.8271 28.2124 18.904 28.2438C18.9809 28.2752 19.0633 28.2911 19.1463 28.2907Z" fill="black" />
                <path d="M13.4687 22.6092H28.6187C28.7862 22.6092 28.9467 22.5427 29.0651 22.4243C29.1835 22.3059 29.25 22.1453 29.25 21.9779C29.25 21.8105 29.1835 21.65 29.0651 21.5316C28.9467 21.4132 28.7862 21.3467 28.6187 21.3467H13.4687C13.3013 21.3467 13.1408 21.4132 13.0224 21.5316C12.904 21.65 12.8375 21.8105 12.8375 21.9779C12.8375 22.1453 12.904 22.3059 13.0224 22.4243C13.1408 22.5427 13.3013 22.6092 13.4687 22.6092Z" fill="black" />
            </g>
            <circle cx="21.0417" cy="21.9782" r="20.5417" fill="#D9D9D9" fill-opacity="0.03" stroke="#FDD220" />
            <defs>
                <clipPath id="clip0_2289_8985">
                    <rect width="20.2" height="20.2" fill="white" transform="matrix(-1 0 0 1 31.1445 11.8779)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { ArrowSvg };