import React from 'react'
import ChatToTask from './chattotask';
import Calendar from './calendar';
import Emails from './email';
import AiIntelligence from './ai-intelligence';
import PersonalisationAndIntegration from './personalisation-integration';
import BottomUpApproach from './bottom-up-approach';
import MobileFirst from './mobile-first';
import Top7Ways from './top-7-ways';
import { Link } from 'react-scroll';
import { FaAngleRight } from "react-icons/fa";
import { CheckImgSvg } from '../../svg';
import API from '../../../../../utils/API';
import axios from 'axios';

export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Top 7 ways Workfast.ai can Revolutionise your project management', id: 'top-7-ways-workfast.ai' },
        { title: 'Mobile first', id: 'mobile-first' },
        { title: 'Bottom Up approach in Attaining Team Goals or Company Goals', id: 'bottom-up-approach' },
        { title: 'Personalisation and Integration ', id: 'personalisation-integration' },
        { title: 'AI intelligence', id: 'ai-intelligence' },
        { title: 'Email ', id: 'email' },
        { title: 'Calendar', id: 'calendar' },
        { title: 'Chat to Task', id: 'chat-to-task' },
    ];

    const handleBookClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "BOOK_FOR_DEMO_BLOG_ARTICLE3"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };

    return (
        <div className='sm:flex px-[20px] md:px-0 md:w-[88%] mx-auto gap-[40px] relative z-[1] mb-[20px] sm:mb-[60px] lg:mb-[100px]'>
            <div className='flex flex-col gap-[24px] md:gap-[30px] lg:gap-[50px] w-full md:w-[65%] overflow-y-auto'>
                <div id='top-7-ways-workfast.ai'><Top7Ways /></div>
                <div id='mobile-first'><MobileFirst /></div>
                <div id='bottom-up-approach'><BottomUpApproach /></div>
                <div id='personalisation-integration'><PersonalisationAndIntegration /></div>
                <div id='ai-intelligence'><AiIntelligence /></div>
                <div id='email'><Emails /></div>
                <div id='calendar'><Calendar /></div>
                <div id='chat-to-task'><ChatToTask /></div>
            </div>
            <aside className='sticky right-0 top-[40px] self-start w-[35%] rounded-[8px] hidden md:block '>
                <h3 className='text-[#000000] text-[18px] lg:text-[22px] font-medium pl-[9px]'>Table of contents</h3>
                <ul className={`mt-[10px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section, index) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                className={`flex gap-[10px] lg:gap-[17px] p-[9px] cursor-pointer rounded-[6px] items-start ${activeSection === section.id ? "bg-[#FFF5C9]" : "bg-transparent"}`}
                                onSetActive={handleSetActive}
                            >
                                <FaAngleRight className='mt-[2px] lg:mt-[4px] xl:mt-[6px] text-[#000000] text-[16px] lg:text-[20px]' />
                                <h3 className='text-[#000000] text-[16px] lg:text-[20px] font-medium'>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className='mt-[40px] border-t border-[#A8A8A8] pt-[31px] flex flex-col gap-[12px]'>
                    <h3 className='text-[#000000] font-extrabold text-[17px]'>Create your own workspace in  Workfast today</h3>
                    <div className='flex flex-col gap-[10px]'>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#252525]'>
                            <CheckImgSvg />
                            <h4>Communicate with your team</h4>
                        </div>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#252525]'>
                            <CheckImgSvg />
                            <h4>Add and complete Tasks</h4>
                        </div>
                        <div className='flex gap-[8px] items-center text-[14px] font-normal text-[#252525]'>
                            <CheckImgSvg />
                            <h4>Engage with your team</h4>
                        </div>
                    </div>
                    <button className='bg-gradient-to-l from-[#FDD01F] via-[#FDD320] to-[#F8861B] w-9/12 py-[13px] rounded-[14px] text-[#221B00] text-[16px] font-semibold' onClick={(e) => { handleBookClick(e) }}>Get Started</button>
                </div>
            </aside>
        </div>
    )
}
