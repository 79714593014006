import React from 'react'
import API from '../../../../../utils/API';
import axios from 'axios';

export default function Banner() {

  const handleBookClick = async (e) => {
    e.preventDefault();
    const response = await axios.post(API.BUTTON,
        {
            "eventName": "BOOK_FOR_DEMO_HOME"
        }
    );
    if (response.data.statusCode === 200) {
        window.location.href = '/book-for-demo';
    }
};

  return (
    <div className='mt-[20px] flex flex-col gap-[20px] justify-center items-center mx-auto relative z-[15] w-full px-[20px]'>
        <div className='flex flex-col items-center justify-center text-center gap-[16px]'>
            <h1 className='text-[#180833] text-[26px] font-bold'>Workfast helps you to get the most valuable thing in the world, "Your Time".</h1>
            <h2 className='text-[#180833] text-[16px] font-medium'>Workfast.ai is designed and developed to save your time by bringing in Focus and Productivity.</h2>
            <button className='bg-[#FDD01F] rounded-[8px] w-full py-[12px] text-[#181000] text-[14px] font-semibold' onClick={(e) => {handleBookClick(e)}}>Book a Demo</button>
        </div>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/banner-project.webp"  alt='workfast' loading='lazy' width={400} height={400}/>
    </div>
  )
}
