import React from 'react'
import { ArchiveProjectSvg, DeleteProjectSvg, EditProjectSvg, MoveToFolderSvg, MoveToSpaceSvg } from '../../../svg';

export default function EllipsisIcon() {
    return (
        <div className='flex flex-col gap-[11px] justify-center'>
            <h3 className='text-[#000000] text-[18px] font-bold'>Ellipsis Icon (3-dot menu)</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project22.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
            <div className='mt-[6px]'>
                <p className='text-[#000000] text-[14px] font-medium'>Access additional options and actions by clicking the ellipsis icon (three dots) next to tasks, projects, or items. This menu provides shortcuts to features like editing, deleting, or customizing settings. Use the ellipsis icon to streamline your interactions and quickly perform actions.</p>
                <div className='mt-[11px] flex flex-col gap-[11px]'>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <MoveToSpaceSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#FFDD09] font-semibold'>Move to Space</span> : Move a project seamlessly from one space to another using the <span className='font-semibold'>"Move to Space"</span> option in Ellipsis Menu.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <MoveToFolderSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#15BB5C] font-semibold'>Move to Folder</span> : Move a project seamlessly from one folder to another using the <span className='font-semibold'>"Move to Folder"</span> option in Ellipsis Menu.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <EditProjectSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#28BFFF] font-semibold'>Edit Project</span> : Edit the project's name, description, deadline, tags, or assigned members from the Ellipsis Menu.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <ArchiveProjectSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#9665FF] font-semibold'>Archive</span> : Move projects to the archive while keeping them accessible for future reference.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <DeleteProjectSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#FF4545] font-semibold'>Delete Project</span> : Permanently remove the project and all its data.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
