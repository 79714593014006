import React from 'react'
import { AddChannelSvg, ChannelClownSvg, ChannelIconSvg, ChannelMessageSvg, ChannelRocketSvg, ChannelsSvg, MessagesSvg, ThreadSvg } from './svg';



export default function Channel() {
    const [cardButton, setCardButton] = React.useState(0);
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='lg:flex gap-[60px] xl:gap-[90px] items-end relative z-[15]'>
            <div className='relative z-[10]'>
                <div className='flex gap-[9px] items-center'>
                    <ChannelIconSvg size={position >= 1440 ? "40" : "35"} />
                    <h3 className='text-[#000000] text-[26px] xl:text-[32px] font-semibold'>Channel</h3>
                </div>
                <div className='flex gap-[20px] items-center lg:justify-center flex-col mt-[26px] xl:mt-[32px]'>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 0 ? "scale-[1.08]" : ""}`} onClick={() => { setCardButton(0) }}>
                        <MessagesSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Public and Private Channels</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Choose channel visibility</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 1 ? "scale-[1.08]" : ""}`} onClick={() => { setCardButton(1) }}>
                        <ChannelsSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Add to Task</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Turn messages into action items</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 2 ? "scale-[1.08]" : ""}`} onClick={() => { setCardButton(2) }}>
                        <ThreadSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Channel Options</h5>
                            <p className='text-[#1D272C] text-[16px] xl:text-[16px] font-normal'>Save, Pin, Remind, Reply, Copy, any messages</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 3 ? "scale-[1.08]" : ""}`} onClick={() => { setCardButton(3) }}>
                        <AddChannelSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Check in - Check out</h5>
                            <p className='text-[#1D272C] text-[16px] xl:text-[16px] font-normal'>Real time employee work status</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative z-[10] mt-[80px] sm:mt-[140px] lg:mt-0'>
                <div className='absolute -top-[60px] sm:-top-[80px] w-[50px] sm:w-auto'>
                    <ChannelMessageSvg size={position >= 645 ? "112" : "50"}/>
                </div>
                <div className='absolute -right-[20px] sm:-right-[80px] -top-[30px] sm:-top-[70px] w-[30px] sm:w-auto '>
                    <ChannelClownSvg size={position >= 645 ? "88" : "30"} />
                </div>
                <div className='absolute right-[40px] -bottom-[60px] sm:-bottom-[80px] w-[80px] sm:w-auto'>
                    <ChannelRocketSvg size={position >= 645 ? "153" : "80"} />
                </div>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/channel-bg.png" alt='workfast' loading='lazy' className='absolute -bottom-[10px] sm:-bottom-[20px] -right-[10px] sm:-right-[20px] z-0' />
                {cardButton === 0 ?
                    <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/channel/channel1.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                    : cardButton === 1 ?
                        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/channel/channel2.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                        : cardButton === 2 ?
                            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/channel/channel3.mov" autoPlay loop muted playsInline className='xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                            : cardButton === 3 &&
                            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/channel/channel4.mov" autoPlay loop muted playsInline className=' xl:w-[653px] xl:h-[428px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                }
            </div>
        </div >
    )
}
