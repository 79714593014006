import React from 'react'
import axios from 'axios';
import API from '../../../../../utils/API';
import { GoAlert } from "react-icons/go";
import { CompanySvg, ContactSvg, EmailSvg, EmployeeSvg, ProfileSvg, RoleSvg } from "./svg";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { IoIosArrowRoundForward } from "react-icons/io";

export default function Form() {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [phoneNumberError, setPhoneNumberError] = React.useState(false);
    const [companyName, setCompanyName] = React.useState('');
    const [roleName, setRoleName] = React.useState('');
    const [peopleCount, setPeopleCount] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [position, setPosition] = React.useState(window.innerWidth <= 645);
    const [isFirstRender, setIsFirstRender] = React.useState(true);


    const handleNameChange = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            setName(e.target.value)
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        const email = e.target.value;
        const gmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === "") {
            setEmailError(false);
        }
        else if (!gmailRegex.test(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }

    const handleCompanyNameChange = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            setCompanyName(e.target.value);
        }
    }

    const handleRoleNameChange = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            setRoleName(e.target.value);
        }
    }

    const handlePhoneNumberChange = (phone, country) => {
        if (isFirstRender) {
            setIsFirstRender(false);
            return;
        }
        const phoneUtil = PhoneNumberUtil.getInstance();
        const dialCode = country?.country?.dialCode || "";
        const phoneWithoutPlus = phone.startsWith(`+${dialCode}`) ? phone.slice(dialCode.length + 1) : phone;
        let isValid = false;
        try {
            const parsedPhone = phoneUtil.parse(phone, country?.country?.iso2);
            isValid = phoneUtil.isValidNumber(parsedPhone);
        } catch (error) {
            isValid = false;
        }
        if (!isValid || phoneWithoutPlus === "") {
            setPhoneNumberError(true)
        } else {
            setPhoneNumberError(false)
        }
        setPhoneNumber(phone);
    }

    const handlePeopleCountChange = (e) => {
        const peopleCounts = e.target.value.replace(/\D/g, '');
        if (peopleCounts !== '' && parseInt(peopleCounts, 10) > 0) {
            setPeopleCount(peopleCounts);
        } else {
            setPeopleCount('');
        }
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true)
            const { data } = await axios.post(API.GOOGLE_LEADS,
                {
                    name: name,
                    email: email,
                    phone: phoneNumber,
                    company_name: companyName,
                    people_count: peopleCount,
                    role_name: roleName
                });
            // const createTask = await axios.post(API.CREATE_TASK,
            //     {
            //         projectId: 5866,
            //         title: "Google Leads",
            //         description: `name: ${name}, email: ${email}, phone: ${phoneNumber} companyName: ${companyName}, peopleCount: ${peopleCount}, roleName: ${roleName}`,
            //         createdUser: 25578854,
            //         assignee: [25578854],
            //     },
            //     {
            //         headers: {
            //             form: "bookdemo",
            //         },
            //     });
            if (data?.statusCode === 200) {
                setName('');
                setEmail('');
                setPhoneNumber('');
                setCompanyName('');
                setPeopleCount('');
                setRoleName('');
            }
            setLoading(false);
            window.location.href = '/google/success';
        } catch (error) {
            var statusCode = error?.response?.status;
            if (statusCode === 401) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    };

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 645);
        };
    }, []);
    return (
        <div className='mt-[60px] sm:mt-[200px] relative z-[15] px-[20px] sm:px-0' id="form">
            <h3 className=' text-[#272727] text-[20px] sm:text-[26px] lg:text-[34px] xl:text-[40px] text-center font-bold'>Register for the Business Success Process Setting <br className='hidden sm:block' /> Framework Through Workfast.ai.</h3>
            <div className='mt-[20px] sm:mt-[60px] w-full lg:w-[808px] p-[20px] lg:p-[32px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-[30px] lg:gap-[52px] rounded-[12px]' style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.4)" }}>
                <div className='w-full'>
                    <div className='flex items-center gap-1'>
                        <div className='size-[28px] rounded-[6px] bg-[#FF950040] flex justify-center items-center'>
                            <ProfileSvg size={position ? "19" : "23"} />
                        </div>
                        <h3 className='text-[#080808] text-[12px] md:text-[17px] font-medium'>Name*</h3>
                    </div>
                    <div className={`w-full h-[38px] md:h-[44px] bg-[#F4F4F4] border-[1px] border-[#E8E8E8] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                        <input type='text' className='w-full text-[#080808] placeholder:text-[#080808]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] ml-[12px]' value={name} placeholder='Enter your name' onChange={(e) => { handleNameChange(e); }} />
                    </div>
                </div>
                <div className='w-full relative'>
                    <div className='flex items-center gap-1'>
                        <div className='size-[28px] rounded-[6px] bg-[#FFCC0040] flex justify-center items-center'>
                            <EmailSvg size={position ? "19" : "23"} />
                        </div>
                        <h3 className='text-[#080808] text-[12px] md:text-[17px] font-medium'>Email*</h3>
                    </div>
                    <div className={`w-full h-[38px] md:h-[44px] bg-[#F4F4F4] border-[1px] lg:py-[10px] mt-[10px] rounded-[8px] ${emailError ? "border-[1px] border-[#F92A4B]" : "border-[#E8E8E8]"}`}>
                        <input type='email' className='w-full text-[#080808] placeholder:text-[#080808]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] ml-[12px]' placeholder='Enter your email' value={email} onChange={(e) => { handleEmailChange(e) }} />
                    </div>
                    {emailError && (
                        <div className='absolute flex gap-[10px] items-center text-[12px] sm:text-[14px] text-red-700 font-medium -bottom-[23px] sm:-bottom-[28px]'>
                            <GoAlert />
                            <div>Please Enter Your Work Email</div>
                        </div>
                    )}
                </div>
                <div className='w-full'>
                    <div className='flex items-center gap-1'>
                        <div className='size-[28px] rounded-[6px] bg-[#34C75940] flex justify-center items-center'>
                            <ContactSvg size={position ? "19" : "23"} />
                        </div>
                        <h3 className='text-[#080808] text-[12px] md:text-[17px] font-medium'>Phone Number*</h3>
                    </div>
                    <div className={`w-full h-[38px] md:h-[44px] bg-[#F4F4F4] border-[1px] lg:py-[10px] mt-[10px] rounded-[8px] ${phoneNumberError ? "border-[1px] border-[#F92A4B]" : "border-[#E8E8E8]"}`}>
                        {/* <input type='text' className='w-full text-[#080808] placeholder:text-[#080808]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%]' placeholder='Enter your Phone number' value={phoneNumber} onChange={(e) => { handlePhoneNumberChange(e) }} /> */}
                        <PhoneInput defaultCountry="in" value={phoneNumber} onChange={(phone, country) => { handlePhoneNumberChange(phone, country) }} className="w-full text-[#080808] placeholder:text-[#080808]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] mx-auto booking" />
                    </div>
                </div>
                <div className='w-full'>
                    <div className='flex items-center gap-1'>
                        <div className='size-[28px] rounded-[6px] bg-[#00C7BE40] flex justify-center items-center'>
                            <CompanySvg size={position ? "19" : "23"} />
                        </div>
                        <h3 className='text-[#080808] text-[12px] md:text-[17px] font-medium'>Company name*</h3>
                    </div>
                    <div className={`w-full h-[38px] md:h-[44px] bg-[#F4F4F4] border-[1px] border-[#E8E8E8] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                        <input type='text' className='w-full text-[#080808] placeholder:text-[#080808]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] ml-[12px]' placeholder='Enter your Company name' value={companyName} onChange={(e) => { handleCompanyNameChange(e) }} />
                    </div>
                </div>
                <div className='w-full'>
                    <div className='flex items-center gap-1'>
                        <div className='size-[28px] rounded-[6px] bg-[#30B0C740] flex justify-center items-center'>
                            <RoleSvg size={position ? "19" : "23"} />
                        </div>
                        <h3 className='text-[#080808] text-[12px] md:text-[17px] font-medium'>What is your Role in The Company</h3>
                    </div>
                    <div className={`w-full h-[38px] md:h-[44px] bg-[#F4F4F4] border-[1px] border-[#E8E8E8] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                        <input type='text' className='w-full text-[#080808] placeholder:text-[#080808]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] ml-[12px]' placeholder='Enter your Company name' value={roleName} onChange={(e) => { handleRoleNameChange(e) }} />
                    </div>
                </div>
                <div className='w-full'>
                    <div className='flex items-center gap-1'>
                        <div className='size-[28px] rounded-[6px] bg-[#007AFF40] flex justify-center items-center'>
                            <EmployeeSvg size={position ? "19" : "23"} />
                        </div>
                        <h3 className='text-[#252525] text-[12px] md:text-[17px] font-medium'>No of Employee / College Staff</h3>
                    </div>
                    <div className={`w-full h-[38px] md:h-[44px] bg-[#F4F4F4] border-[1px] border-[#E8E8E8] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                        <input type='text' className='w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] ml-[12px]' placeholder='Enter Number of People in your company' value={peopleCount} onChange={(e) => { handlePeopleCountChange(e) }} />
                    </div>
                </div>
            </div>
            {loading
                ?
                <div class="loader"></div>
                :
                (
                    <div className={`w-[210px] h-[55px] rounded-full bg-[#FFCC00] mx-auto flex gap-[12px] justify-center items-center cursor-pointer border-[#FFEEAA] border-[4px] text-[#221B00] text-[16px] font-bold mt-[30px] ${name === "" || email === "" || emailError || phoneNumber === "" || phoneNumberError || companyName === "" || roleName === "" || peopleCount === '' ? "opacity-30" : ""}`} disabled={name === "" || email === "" || emailError || phoneNumber === "" || phoneNumberError || companyName === "" || roleName === "" || peopleCount === ''} onClick={(e) => { handleSubmit(e) }}>
                        <div>Book now</div>
                        <IoIosArrowRoundForward />
                    </div>
                )}
        </div>
    )
}
