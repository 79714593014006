import React from 'react'
import { ProjectBulbSvg, ProjectClownSvg, ProjectIconSvg, ProjectMsgSvg, ProjectsSvg, SpaceSvg, StatusesSvg, TaskSvg } from './svg';



export default function Project() {
    const [cardButton, setCardButton] = React.useState(0);
    const [position, setPosition] = React.useState(window.innerWidth);
    
    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col-reverse lg:flex-row gap-[130px] sm:gap-[160px] lg:gap-[60px] xl:gap-[90px] items-end relative z-[15]'>
            <div className='relative z-[10]'>
                <div className='absolute -top-[110px] sm:-top-[125px] right-[20px] sm:right-[80px] w-[80px] sm:w-auto'>
                    <ProjectBulbSvg size={position >= 645 ? "119" : "80"}/>
                </div>
                <div className='absolute -left-[20px] sm:-left-[80px] -top-[30px] sm:-top-[70px] w-[30px] sm:w-auto'>
                    <ProjectClownSvg size={position >= 645 ? "88" : "30"}/>
                </div>
                <div className='absolute -left-[80px] bottom-[40px] hidden sm:flex'>
                    <ProjectMsgSvg />
                </div>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/project-bg.png" alt='workfast' loading='lazy' className='absolute -bottom-[10px] sm:-bottom-[20px] -left-[10px] sm:-left-[20px] z-0' />
                {cardButton === 0 ?
                    <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/project/project1.mov" autoPlay loop muted playsInline className='xl:w-[653px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                    : cardButton === 1 ?
                        <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/project/project2.mov" autoPlay loop muted playsInline className='xl:w-[653px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                        : cardButton === 2 ?
                            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/project/project3.mov" autoPlay loop muted playsInline className='xl:w-[653px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                            : cardButton === 3 &&
                            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/home/project/project4.mov" autoPlay loop muted playsInline className='xl:w-[653px] rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10'></video>
                }
            </div>
            <div className='relative z-[10] w-full lg:w-auto'>
                <div className='flex gap-[9px] items-center'>
                    <ProjectIconSvg size={position >= 1440 ? "40" : "35"} />
                    <h3 className='text-[#000000] text-[26px] xl:text-[32px] font-semibold'>Project</h3>
                </div>
                <div className='flex gap-[20px] items-center lg:justify-center flex-col mt-[26px] xl:mt-[32px]'>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 0 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(0) }}>
                        <SpaceSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Space</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Group all similar project in one space</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 1 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(1) }}>
                        <ProjectsSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Projects</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Run multiple projects seamlessly</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 2 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(2) }}>
                        <TaskSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Upload Files</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>Drag & Drop or Upload Files to specific task</p>
                        </div>
                    </div>
                    <div className={`w-full lg:w-[300px] xl:w-[390px] h-[90px] rounded-[8px] bg-[#FFF] flex gap-[8px] items-start pl-[16px] pt-[16px] xl:pl-[20px] transition-all duration-300 shadow-md cursor-pointer ${cardButton === 3 ? "scale-[1.05]" : ""}`} onClick={() => { setCardButton(3) }}>
                        <StatusesSvg size={position >= 1440 ? "25" : "20"} />
                        <div className='text-start'>
                            <h5 className='text-[#180833] text-[16px] xl:text-[20px] font-medium'>Stage View</h5>
                            <p className='text-[#1D272C] text-[14px] xl:text-[16px] font-normal'>View projects in workflow stages</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
