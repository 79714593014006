import React from 'react'
import { PlaywellSvg, ReplaceEntireSvg } from '../svg';

export default function Desktop() {
    return (
        <div className='mt-[60px] xl:mt-[90px] w-10/12 mx-auto'>
            <div className='flex flex-col gap-[40px] lg:gap-[60px] xl:gap-[90px] justify-center items-center w-full text-center'>
                <div>
                    <h3 className='text-[#000000] text-[32px] lg:text-[40px] xl:text-[47px] font-bold'>Meet Your Team's Expectations to Foster <br /> A Better Work Culture</h3>
                </div>
                <div className='grid lg:grid-cols-2 xl:grid-cols-3 justify-center gap-x-[30px] gap-[20px] lg:gap-x-[40px] lg:gap-y-[24px] relative'>
                    <div className='w-full border-[#FF3B30] border p-[9px] rounded-[35px] col-span-1'>
                        <div className='bg-[#F6F5F4] w-full border-[#FF3B30] border rounded-[24px] h-full relative flex flex-col justify-between'>
                            <div className='p-[22px] text-start'>
                                <p className='text-[#000000] text-[20px] font-bold'>Personalize Your Workspace</p>
                                <p className='text-[#585858] text-[16px] font-medium'>Gantt, Kanban, List, Board, box, <br /> Priority etc</p>
                            </div>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/view-work.png" alt='card1' loading='lazy' className='w-[300px] ml-auto rounded-br-[24px]' width={300} height={300} />
                        </div>
                    </div>
                    <div className='w-full border-[#FF9500] border p-[9px] rounded-[35px] xl:relative'>
                        <div className='bg-[#F6F5F4] w-full border-[#FF9500] border rounded-[24px] h-full relative flex flex-col justify-between'>
                            <div className='p-[22px] text-start'>
                                <p className='text-[#000000] text-[20px] font-bold'>Simplify In One Tab</p>
                                <p className='text-[#585858] text-[16px] font-medium'>Personalise And Customise Navigation <br /> Bar With One Click</p>
                            </div>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/customize-click.png" alt='card1' loading='lazy' className='w-[300px] mx-auto' width={300} height={300}/>
                        </div>
                    </div>
                    <div className='w-full border-[#FFCC00] border p-[9px] rounded-[35px]'>
                        <div className='bg-[#F6F5F4] w-full border-[#FFCC00] border rounded-[24px] h-full relative flex flex-col justify-between'>
                            <div className='p-[22px] text-start'>
                                <p className='text-[#000000] text-[20px] font-bold'>Stay Organized Every Day</p>
                                <p className='text-[#585858] text-[16px] font-medium'>Organize Your Work, Reminders, <br /> And Calendar Events</p>
                            </div>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/whats-next.png" alt='card1' loading='lazy' className='w-[300px] ml-auto rounded-br-[24px]' width={300} height={300}/>
                        </div>
                    </div>
                    <div className='w-full lg:col-span-2 xl:col-span-3 border-[#00C7BE] border p-[9px] rounded-[35px]'>
                        <div className='w-full border border-[#00C7BE] rounded-[24px] lg:h-[380px] flex flex-col lg:flex-row justify-between items-center relative'>
                            <div className='bg-[#F6F5F4] w-full h-full rounded-t-[24px] lg:rounded-l-[24px] relative z-0 py-[51px]'>
                                <div className='w-9/12 xl:w-1/2 mx-auto text-start'>
                                    <p className='font-bold text-[#000000] text-[23px]'>Seamlessly Connects</p>
                                    <p className='font-medium text-[#585858] text-[16px] mt-[9px]'>Seamlessly Integrates With The Tools <br /> You Rely On Daily.</p>
                                    <div className='mt-[16px]'>
                                        <PlaywellSvg />
                                    </div>
                                </div>
                            </div>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/cave.png" alt='cave' className='absolute top-[40%] lg:top-0 -translate-y-1/2 lg:-translate-y-0 left-[169px] lg:left-[40%] xl:left-[45%] -translate-x-1/2 z-1 rotate-90 lg:rotate-0 w-[170px] lg:w-auto' />
                            <div className='size-[50px] rounded-full border border-[#D4D4D4] flex justify-center items-center text-[#000] text-[14px] font-semibold absolute bg-[#FFF] top-1/2 -translate-x-1/2 left-[53%] -translate-y-1/2'>or</div>
                            <div className='bg-[#F6F5F4] w-full h-full rounded-b-[24px] lg:rounded-r-[24px] py-[51px]'>
                                <div className='w-9/12 xl:w-1/2 mx-auto text-start'>
                                    <p className='font-bold text-[#000000] text-[23px]'>All-in-One Solution</p>
                                    <p className='font-medium text-[#585858] text-[16px] mt-[9px]'>Streamline Your Tools and Reduce <br /> Software Costs.</p>
                                    <div className='mt-[16px]'>
                                        <ReplaceEntireSvg />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
