import React from 'react'
import { PlaywellSvg, ReplaceEntireSvg } from '../svg';

export default function Mobile() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='mt-[20px] w-full px-[20px] mx-auto'>
            <div className='flex flex-col gap-[20px] justify-center items-center w-full text-center'>
                <div>
                    <h3 className='text-[#000000] text-[32px] font-bold'>Meet Your Team's Expectations to Foster A Better Work Culture</h3>
                </div>
                <div className='grid grid-cols-1 gap-y-[20px]'>
                    <div className='w-full border-[#FF3B30] border p-[9px] rounded-[35px] col-span-1'>
                        <div className='bg-[#F6F5F4] w-full border-[#FF3B30] border rounded-[24px] h-full relative flex flex-col justify-between'>
                            <div className='p-[22px] text-start'>
                                <p className='text-[#000000] text-[20px] font-bold'>Personalize Your Workspace</p>
                                <p className='text-[#585858] text-[16px] font-medium'>Gantt, Kanban, List, Board, box, Priority etc</p>
                            </div>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/view-work.png" alt='card1' loading='lazy' className='w-[260px] ml-auto rounded-br-[24px]' width={260} height={260} />
                        </div>
                    </div>
                    <div className='w-full border-[#FF9500] border p-[9px] rounded-[35px]'>
                        <div className='bg-[#F6F5F4] w-full border-[#FF9500] border rounded-[24px] h-full relative flex flex-col justify-between'>
                            <div className='p-[22px] text-start'>
                                <p className='text-[#000000] text-[20px] font-bold'>Simplify In One Tab</p>
                                <p className='text-[#585858] text-[16px] font-medium'>Personalise And Customise Navigation Bar With One Click</p>
                            </div>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/customize-click.png" alt='card1' loading='lazy' className='w-[260px] mx-auto' width={260} height={260}/>
                        </div>
                    </div>
                    <div className='w-full border-[#FFCC00] border p-[9px] rounded-[35px]'>
                        <div className='bg-[#F6F5F4] w-full border-[#FFCC00] border rounded-[24px] h-full relative flex flex-col justify-between'>
                            <div className='p-[22px] text-start'>
                                <p className='text-[#000000] text-[20px] font-bold'>Stay Organized Every Day</p>
                                <p className='text-[#585858] text-[16px] font-medium'>Organize Your Work, Reminders, And Calendar Events</p>
                            </div>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/whats-next.png" alt='card1' loading='lazy' className='w-[260px] ml-auto rounded-br-[24px]'  width={260} height={260}/>
                        </div>
                    </div>
                    <div className='w-full border-[#00C7BE] border p-[9px] rounded-[35px]'>
                        <div className='w-full border border-[#00C7BE] rounded-[24px] lg:h-[380px] flex flex-col justify-between items-center relative'>
                            <div className='bg-[#F6F5F4] w-full h-full rounded-t-[24px] relative z-0 py-[51px]'>
                                <div className='w-11/12 mx-auto text-start'>
                                    <p className='font-bold text-[#000000] text-[23px]'>Seamlessly Connects</p>
                                    <p className='font-medium text-[#585858] text-[16px] mt-[9px]'>Seamlessly Integrates With The Tools You Rely On Daily.</p>
                                    <div className='mt-[16px] flex justify-center'>
                                        <PlaywellSvg />
                                    </div>
                                </div>
                            </div>
                            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/mobile-cave.png" alt='cave' className={`absolute -translate-y-1/2 inset-x-0 z-1 w-full ${position >= 385 ? "top-[44%]" : "top-[45%]"}`} />
                            <div className='size-[50px] rounded-full border border-[#D4D4D4] flex justify-center items-center text-[#000] text-[14px] font-semibold absolute bg-[#FFF] top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2'>or</div>
                            <div className='bg-[#F6F5F4] w-full h-full rounded-b-[24px] lg:rounded-r-[24px] py-[51px]'>
                                <div className='w-11/12 mx-auto text-start'>
                                    <p className='font-bold text-[#000000] text-[23px]'>All-in-One Solution</p>
                                    <p className='font-medium text-[#585858] text-[16px] mt-[9px]'>Streamline Your Tools and Reduce Software Costs.</p>
                                    <div className='mt-[16px] flex justify-center'>
                                        <ReplaceEntireSvg />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}