import React from 'react'
import { AssigneByMeSvg, AssignedToMeSvg, CompletedTaskSvg, MainSubTaskSvg, OverdueTaskSvg, PeopleSvg, PrioritySvg, StatusSvg, TagSvg } from '../../../svg';


export default function Filterby() {
    return (
        <div className='flex flex-col gap-[11px] justify-center'>
            <h3 className='text-[#000000] text-[18px] font-bold'>Filter by</h3>
            <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Project/project11.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
            <div className='mt-[6px]'>
                <p className='text-[#000000] text-[14px] font-medium'>Quickly find the tasks you need by using the “Filter” option. Narrow down your task list by criteria like status, assignee, due date, or tags. Start filtering to streamline your task management today!</p>
                <div className='mt-[11px] flex flex-col gap-[11px]'>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <AssigneByMeSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#FF3B30] font-semibold'>Assigned by me</span> : Assigned by Me lists tasks you’ve assigned to others.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <AssignedToMeSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#FF9500] font-semibold'>Assigned to me</span> : Assigned to Me lists tasks assigned to you.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <CompletedTaskSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#FFCC00] font-semibold'>Completed tasks</span> : Completed Tasks shows tasks that are finished.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <PeopleSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-seminormalbold'><span className='text-[#34C759] font-semibold'>People</span> : People displays all team members or participants.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <PrioritySvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#00C7BE] font-semibold'>Priority</span> : Priority indicates the importance or urgency of a task.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <StatusSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#007AFF] font-semibold'>Status</span> : Status shows the current state or progress of a task.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <TagSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#5856D6] font-semibold'>Tag</span> : Tag labels or categorizes tasks for easier organization and filtering.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <OverdueTaskSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#AF52DE] font-semibold'>Overdue Tasks</span> : Overdue Tasks shows tasks that have passed their due date.</p>
                    </div>
                    <div className='flex gap-[8px] items-start'>
                        <div className='flex shrink-0'>
                            <MainSubTaskSvg size="20" />
                        </div>
                        <p className='text-[#000] text-[14px] font-normal'><span className='text-[#FF2D55] font-semibold'>Main & Sub Tasks</span> : Main & Sub Tasks organize primary tasks with related smaller tasks.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
