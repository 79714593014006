import React from 'react'
import { TbCircleCheckFilled, TbCircleMinus } from 'react-icons/tb';
import { MondayCharacterSvg, MondayIconSvg, MondaySvg, WorkfastSvg } from '../../../svg';
import API from '../../../../../../utils/API';
import axios from 'axios';



export default function Comparison() {
    const list = [
        { title: 'Date view', monday: 'NO', workfast: 'YES' },
        { title: 'Project view', monday: 'NO', workfast: 'YES' },
        { title: 'Priority view', monday: 'NO', workfast: 'YES' },
        { title: 'People View', monday: 'NO', workfast: 'YES' },
        { title: 'Stage view', monday: 'NO', workfast: 'YES' },
        { title: 'Gantt chart', monday: 'YES', workfast: 'YES' },
        { title: 'Kanban view', monday: 'YES', workfast: 'YES' },
        { title: 'Board View', monday: 'NO', workfast: 'YES' },
        { title: 'Official task & Personal task', monday: 'NO', workfast: 'YES' },
        { title: 'Inbox', monday: 'NO', workfast: 'YES' },
        { title: 'Tasks and subtasks', monday: 'NO', workfast: 'YES' },
        { title: 'Individual and group Chats', monday: 'NO', workfast: 'YES' },
        { title: 'Public and Private Channels', monday: 'NO', workfast: 'YES' },
        { title: 'Add to task from chat and channel', monday: 'NO', workfast: 'YES' },
        { title: 'Check-in', monday: 'NO', workfast: 'YES' },
        { title: 'Check -out', monday: 'NO', workfast: 'YES' },
        { title: 'Filter and sort options', monday: 'YES', workfast: 'YES' },
        { title: 'Comment in tasks, subtasks, projects.', monday: 'NO', workfast: 'YES' },
        { title: 'Feed Back Channel', monday: 'NO', workfast: 'YES' },
        { title: 'Voting Channel', monday: 'NO', workfast: 'YES' },
        { title: 'Multiple workspace', monday: 'NO', workfast: 'YES' },
        { title: 'People', monday: 'NO', workfast: 'YES' },
        { title: 'Add many invites', monday: 'NO', workfast: 'YES' },
        { title: 'Drag and Drop documents and files from folders easily', monday: 'NO', workfast: 'YES' },
        { title: 'Embedded email', monday: 'YES', workfast: 'YES' },
        { title: 'Customised navigation bar', monday: 'NO', workfast: 'YES' },
        { title: 'Customise workflow, statuses,Tags, Priority', monday: 'NO', workfast: 'YES' },
        { title: 'Recurring Task', monday: 'YES', workfast: 'YES' },
        { title: 'Customised Analytics Reports', monday: 'NO', workfast: 'YES' },
        { title: 'Daily, Weekly, Monthly, Quarterly Analytics', monday: 'NO', workfast: 'YES' },
        { title: 'People and Profiles', monday: 'NO', workfast: 'YES' },
        { title: 'Feedback channel', monday: 'NO', workfast: 'YES' },
        { title: 'Customisable Project card', monday: 'NO', workfast: 'YES' },
        { title: 'Invite and collaborate in projects, channels, tasks.', monday: 'NO', workfast: 'YES' },
        { title: 'Customised Integration', monday: 'NO', workfast: 'YES' },
    ]

    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const handleBookClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "BOOK_FOR_DEMO_COMPARISON_MONDAY"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };

    return (
        <div className='flex flex-col justify-center items-center w-11/12 sm:w-10/12 mx-auto'>
            <h3 className='text-[#000000] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold sm:w-10/12 lg:w-9/12 text-center'>See why teams</h3>
            <div className='text-[#000000] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center flex flex-wrap gap-x-[12px] items-center justify-center w-11/12 sm:w-auto'>
                <h3>choose Workfast.ai over </h3>
                <div className='w-[50px] xl:w-auto'>
                    <MondaySvg size={position >= 1440 ? "101" : "50"} />
                </div>
                <div className='w-[150px] xl:w-auto'>
                    <MondayCharacterSvg size={position >= 1440 ? "292" : "150"} />
                </div>
            </div>
            <div className='mt-[32px] w-full'>
                <div className='grid grid-cols-4 border-[1px] border-b-[#CECEEA] rounded-t-[6px] sm:rounded-t-[12px] w-full '>
                    <div className='col-span-2 px-[10px] py-[14px] sm:px-[18px] sm:py-[25px]'>
                        <div className='text-[#514B81] text-[12px] sm:text-[16px] font-bold'>Compare features</div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#F002500D] w-full h-full flex flex-col sm:flex-row justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <MondayIconSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] sm:text-[14px] lg:text-[16px] font-bold'>Monday.com</div>
                        </div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#4CB9390D] w-full h-full flex flex-col sm:flex-row  justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <WorkfastSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] ssm:text-[14px] lg:text-[16px] font-bold'>Workfast.ai</div>
                        </div>
                    </div>
                </div>
                {list?.map((each, index) => (
                    <div key={index} className={`grid grid-cols-4 border-b-[1px] border-x-[1px] border-[#CECEEA4D] w-full ${list.length - 1 === index ? "rounded-b-[12px]" : ""}`}>
                        <div className='col-span-2 p-[10px] sm:p-[20px]'>
                            <div className='text-[#24223E] font-semibold text-[10px] sm:text-[15px]'>{each.title}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.monday === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.monday === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.workfast === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.workfast === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-[40px] sm:mt-[100px] flex flex-col justify-center items-center gap-[20px] sm:gap-[40px]'>
                <h3 className='text-[#24223E] text-[22px] sm:text-[30px] lg:text-[42px] font-semibold sm:w-10/12 text-center'>Master your task and achieve your goal with Workfast.ai</h3>
                <button className='w-[170px] h-[40px] lg:w-[270px] lg:h-[64px] rounded-[11px] bg-gradient-to-br from-[#FDD01F] via-[#FDD320] to-[#F8861B] text-[#2A2200] text-[16px] lg:text-[21px] font-semibold cursor-pointer' onClick={(e) => { handleBookClick(e) }}>Try it Free</button>
            </div>
        </div>
    )
}
