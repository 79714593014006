import React from 'react'


export default function Feedback() {
    return (
        <div className='mt-[20px] sm:mt-[60px] lg:mt-[90px] mb-[20px] px-[20px] sm:px-0 w-full sm:w-11/12 mx-auto flex flex-col gap-[20px] sm:gap-[50px] justify-center items-center'>
            <div className='text-center'>
                <h3 className='text-[#000] text-[24px] sm:text-[32px] lg:text-[40px] xl:text-[45px] font-semibold'>What Our Customers Says</h3>
                <h4 className='text-[#383838] text-[18px] lg:text-[20px] xl:text-[22px] font-normal mt-[18px] hidden sm:flex justify-center w-full'>Harmony of Happiness: Cherished Tales from <br/> Our Valued Customers</h4>
                <h4 className='text-[#383838] text-[14px] font-normal mt-[18px] sm:hidden text-center'>Harmony of Happiness: Cherished Tales from Our Valued Customers</h4>
            </div>
            <div className='flex flex-col sm:flex-row gap-[25px] overflow-y-hidden overflow-x-scroll justify-center items-center w-full h-[800px] sm:h-[520px] xl:h-[604px]'>
                <div className={`relative w-[250px] sm:w-[320px] xl:w-[386px] rounded-[9px] sm:rounded-[14px] bg-[#F6F5F4] h-[362px] sm:h-[500px] xl:h-[584px]`} style={{ boxShadow: "0px 4px 10px 0px #4A148C33" }}>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/feedback-1.webp" alt='feedback_1' loading='lazy' className='rounded-t-[14px] w-full' />
                    <div className='relative w-full px-[32px] pb-[27px] mx-auto'>
                        <h4 className='text-[#000000] text-[14px] sm:text-[18px] xl:text-[22px] font-bold'>Sanjay Enishetty</h4>
                        <h5 className='text-[#000000] text-[14px] sm:text-[18px] xl:text-[22px] font-medium'>CEO, Naturals</h5>
                        <h5 className='text-[#3C3C3C] text-[10px] sm:text-[14px] xl:text-[16px] font-medium'>“You are building an amazing product which got the potential and realistic features which most of the startups and SME's like us need, importantly at a affordable cost. We are very happy on the decision we have taken to move from Slack to Workfast.ai”</h5>
                    </div>
                </div>
                <div className={`relative w-[250px] sm:w-[320px] xl:w-[386px] rounded-[9px] sm:rounded-[14px] bg-[#F6F5F4] h-[362px] sm:h-[500px] xl:h-[584px]`} style={{ boxShadow: "0px 4px 10px 0px #4A148C33" }}>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/feedback-2.webp" alt='feedback_1' loading='lazy' className='rounded-t-[14px] w-full' />
                    <div className='relative w-full px-[32px] mx-auto'>
                        <h4 className='text-[#000000] text-[14px] sm:text-[18px] xl:text-[22px] font-bold'>Akhila Rajeshwar</h4>
                        <h5 className='text-[#000000] text-[14px] sm:text-[18px] xl:text-[22px] font-medium'>Executive Director, TiE Chennai</h5>
                        <h5 className='text-[#3C3C3C] text-[10px] sm:text-[14px] xl:text-[16px] font-medium'>I’m amazed at this app, it seems like it was made specifically for our needs. We’ve been waiting for this solution for past 2 years so we are extremely happy. Congratulations to the entire team.</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
