import React from 'react'
import feedback_video from "../../../../../components/videos/feedback.mp4"

export default function Feedback() {
    return (
        <div className='mt-[20px] sm:mt-[60px] lg:mt-[90px] mb-[20px] px-[20px] sm:px-0 w-full bg-[#F8FEFE] relative z-[15]'>
            <div className='text-center'>
                <h3 className='text-[#000] text-[24px] sm:text-[32px] lg:text-[40px] xl:text-[45px] font-semibold'>What Our Customers Says</h3>
                <h4 className='text-[#383838] text-[18px] lg:text-[20px] xl:text-[22px] font-normal mt-[18px] hidden sm:flex justify-center w-full'>Harmony of Happiness: Cherished Tales from <br /> Our Valued Customers</h4>
                <h4 className='text-[#383838] text-[14px] font-normal mt-[18px] sm:hidden text-center'>Harmony of Happiness: Cherished Tales from Our Valued Customers</h4>
            </div>
            <div className='mt-[32px]'>
            <video src={feedback_video} loop controls playsInline className='sm:w-1/2 rounded-[9px] sm:rounded-[17px] xl:rounded-[21px] relative z-10 mx-auto'></video>
            </div>
        </div>
    )
}
