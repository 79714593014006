import React from 'react';
import { AppStoreSvg, PlayStoreSvg, WorkfastLogo } from './svg';

export default function Footer() {

    const [position, setPosition] = React.useState(window.innerWidth <= 645);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 645);
        };
    }, []);

    return (
        <div className='w-full mt-[100px] border-t border-[#CECEEA] relative sm:top-[40px] sm:mb-[40px]'>
            <div className='hidden lg:flex justify-between gap-[20px] xl:gap-[160px] mx-auto py-[45px] lg:w-11/12 px-[20px] relative'>
                <div>
                    <div className='ml-[28px]'>
                        <WorkfastLogo />
                    </div>
                </div>
                <div className='flex items-start w-8/12 justify-between relative z-[1]'>
                    <div className='flex flex-col gap-[15px]'>
                        <h4 className='text-[#292D34] text-[14px] font-semibold'>Insights</h4>
                        <div className='flex flex-col gap-[10px]'>
                            <a title="Slack Alternative" href='/workfast_benefits_slack'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300 relative z-[1]'>Slack Alternative</h5></a>
                            <a title="Task management" href='/workfast_benefits_task_management'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300 relative z-[1]'>Task management</h5></a>
                            <a title="Better than todoist" href='/workfast_benefits_to-do-lists'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Better than todoist</h5></a>
                            <a title="Time and focus" href='/workfast_benefits_time_focus'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Time and focus</h5></a>
                            <a title="Better than WhatsApp" href='/compare/whatsapp-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Better than WhatsApp</h5></a>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[15px] relative z-[1]'>
                        <h4 className='text-[#292D34] text-[14px] font-semibold'>Compare</h4>
                        <div className='flex flex-col gap-[10px]'>
                            <a title="vs Monday" href='/compare/monday-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Monday</h5></a>
                            <a title="vs Asana" href='/compare/asana-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Asana</h5></a>
                            <a title="vs Slack" href='/compare/slack-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Slack</h5></a>
                            <a title="vs Trello" href='/compare/trello-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Trello</h5></a>
                            <a title="vs Notion" href='/compare/notion-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Notion</h5></a>
                            <a title="vs Basecamp" href='/compare/basecamp-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Basecamp</h5></a>
                            <a title="vs Todoist" href='/compare/todoist-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Todoist</h5></a>
                            <a title="vs Smartsheet" href='/compare/smartsheets-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Smartsheet</h5></a>
                            <a title="vs Jira" href='/compare/jira-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Jira</h5></a>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[15px]'>
                        <h4 className='text-[#292D34] text-[14px] font-semibold'>Blog</h4>
                        <div className='flex flex-col gap-[10px] max-w-[282px]'>
                            <a title="Beginner's Guide to Product Management" href='/blog'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Beginner's Guide to Product Management </h5></a>
                            <a title="Breaking Barriers in Communication and Collaboration" href='/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Breaking Barriers in Communication and Collaboration</h5></a>
                            <a title="Top 7 ways Workfast.ai can Revolutionise your project management" href='/blog/top-7-ways-workfast.ai-can-revolutionise-your-project-management'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Top 7 ways Workfast.ai can Revolutionise your Project Management</h5></a>
                            <a title="Looking for a new platform for project management ? Discover Workfast.ai" href='/blog/looking-for-a-new-platform-for-project-management'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Looking for a new platform for Project Management? Discover Workfast.ai</h5></a>
                        </div>
                    </div>

                </div>
            </div>
            <div className='flex gap-[40px] lg:hidden justify-between flex-wrap py-[30px] w-11/12 mx-auto px-[20px] relative'>
                <div>
                    <div className='ml-[28px]'>
                        <WorkfastLogo />
                    </div>
                </div>
                <div className='flex flex-col gap-[15px] relative z-[1]'>
                    <h4 className='text-[#292D34] text-[14px] font-semibold'>Insights</h4>
                    <div className='flex flex-wrap sm:flex-col gap-x-[40px] gap-y-[10px] sm:gap-[10px]'>
                        <a title="Slack Alternative" href='/workfast_benefits_slack'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300 relative z-[1]'>Slack Alternative</h5></a>
                        <a title="Task management" href='/workfast_benefits_task_management'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300 relative z-[1]'>Task management</h5></a>
                        <a title="Better than todoist" href='/workfast_benefits_to-do-lists'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Better than todoist</h5></a>
                        <a title="Time and focus" href='/workfast_benefits_time_focus'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Time and focus</h5></a>
                        <a title="Better than WhatsApp" href='/compare/whatsapp-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Better than WhatsApp</h5></a>
                    </div>
                </div>
                <div className='flex flex-col gap-[15px] relative z-[1]'>
                    <h4 className='text-[#292D34] text-[14px] font-semibold'>Compare</h4>
                    <div className='flex flex-wrap sm:flex-col gap-x-[40px] gap-y-[10px] sm:gap-[10px]'>
                        <a title="vs Monday" href='/compare/monday-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Monday</h5></a>
                        <a title="vs Asana" href='/compare/asana-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Asana</h5></a>
                        <a title="vs Slack" href='/compare/slack-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Slack</h5></a>
                        <a title="vs Trello" href='/compare/trello-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Trello</h5></a>
                        <a title="vs Notion" href='/compare/notion-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Notion</h5></a>
                        <a title="vs Basecamp" href='/compare/basecamp-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Basecamp</h5></a>
                        <a title="vs Todoist" href='/compare/todoist-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Todoist</h5></a>
                        <a title="vs Smartsheet" href='/compare/smartsheets-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Smartsheet</h5></a>
                        <a title="vs Jira" href='/compare/jira-vs-workfast'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>vs Jira</h5></a>
                    </div>
                </div>
                <div className='flex flex-col gap-[15px] relative z-[1]'>
                    <h4 className='text-[#292D34] text-[14px] font-semibold'>Blog</h4>
                    <div className='flex flex-col gap-[10px] max-w-[282px]'>
                        <a title="Beginner's Guide to Product Management" href='/blog'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Beginner's Guide to Product Management </h5></a>
                        <a title="Breaking Barriers in Communication and Collaboration" href='/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Breaking Barriers in Communication and Collaboration</h5></a>
                        <a title="Top 7 ways Workfast.ai can Revolutionise your project management" href='/blog/top-7-ways-workfast.ai-can-revolutionise-your-project-management'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Top 7 ways Workfast.ai can Revolutionise your Project Management</h5></a>
                        <a title="Looking for a new platform for project management ? Discover Workfast.ai" href='/blog/looking-for-a-new-platform-for-project-management'><h5 className='text-[#514B81] text-[14px] font-medium cursor-pointer hover:text-[#000] transition-colors duration-300'>Looking for a new platform for Project Management? Discover Workfast.ai</h5></a>
                    </div>
                </div>
            </div>
            <div className='border-[#CECEEA] border-t-[1px] mx-[8px] py-[30px] relative z-[1]'>
                <div className='w-10/12 mx-auto flex justify-between flex-wrap gap-[20px] items-center relative z-10'>
                    <div className='flex items-center gap-[10px] flex-wrap'>
                        <a title="App Store" href="https://apps.apple.com/in/app/workfast-team-productivity/id6504078096" target="_blank" rel="noopener noreferrer">
                            <div className='flex gap-[10px] sm:gap-[20px] items-center p-[10px] pr-[16px] sm:pr-[22px] border-[1px] border-[#000] bg-[#0D1530] rounded-[14px]'>
                                <AppStoreSvg size={position ? "20" : "30"} />
                                <div className='flex flex-col justify-between'>
                                    <h3 className='text-[#FFFFFF] text-[10px] sm:text-[12px] font-normal'>Download on the</h3>
                                    <h2 className='text-[#FFFFFF] text-[14px] sm:text-[16px] font-semibold'>App Store</h2>
                                </div>
                            </div>
                        </a>
                        <a title="Play Store" href="https://play.google.com/store/apps/details?id=com.workfast.ai.app" target="_blank" rel="noopener noreferrer">
                            <div className='flex gap-[10px] sm:gap-[20px] items-center p-[10px] pr-[16px] sm:pr-[22px] border-[1px] border-[#000] bg-[#0D1530] rounded-[14px]'>
                                <PlayStoreSvg size={position ? "20" : "30"} />
                                <div className='flex flex-col justify-between'>
                                    <h3 className='text-[#FFFFFF] text-[10px] sm:text-[12px] font-normal'>GET IT ON</h3>
                                    <h2 className='text-[#FFFFFF] text-[14px] sm:text-[16px] font-semibold'>Google Play</h2>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='flex gap-[20px] flex-wrap items-center text-[12px] text-[#514B81] font-normal'>
                        <h4>Copyright © 2024, Pepul Tech Privated Limited. All rights reserved.</h4>
                        <a title="Privacy" href='/privacy'><h4 className='cursor-pointer hover:text-[#000] transition-colors duration-300'>Privacy</h4></a>
                        <a title="Terms" href='/terms'><h4 className='cursor-pointer hover:text-[#000] transition-colors duration-300'>Terms</h4></a>
                        <a title="About" href='/about'><h4 className='cursor-pointer hover:text-[#000] transition-colors duration-300'>About</h4></a>
                        <a title="Contact" href='/contact'><h4 className='cursor-pointer hover:text-[#000] transition-colors duration-300'>Contact</h4></a>
                    </div>
                </div>
            </div>
        </div>
    )
}