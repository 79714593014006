import React from 'react'
import { LogoSvg } from '../../../svg';
import API from '../../../../../../utils/API';
import axios from 'axios';


export default function Banner() {
  const [position, setPosition] = React.useState(window.innerWidth <= 645);

  React.useEffect(() => {
    window.onresize = function () {
      setPosition(window.innerWidth <= 645);
    };
  }, []);

  const handleBookClick = async (e) => {
    e.preventDefault();
    const response = await axios.post(API.BUTTON,
      {
        "eventName": "BOOK_FOR_DEMO_BLOG_ARTICLE1"
      }
    );
    if (response.data.statusCode === 200) {
      window.location.href = '/book-for-demo';
    }
  };

  return (
    <div className='flex flex-col justify-center items-center w-10/12 relative mx-auto mt-[20px] lg:mt-[60px] mb-[20px] sm:mb-[60px] xl:mb-[100px]'>
      <div className='relative z-[1] w-[50px] sm:w-auto'>
        <LogoSvg size={position ? "50" : "90"} />
      </div>
      <h2 className='text-[#000000] font-semibold text-[26px] md:text-[36px] lg:text-[50px] xl:text-[60px] relative z-[1] mt-[20px]'>One intelligent </h2>
      <h3 className='text-[#000000] text-[26px] md:text-[36px] lg:text-[50px] xl:text-[60px] font-semibold relative z-[1] text-center'>Platform for your team to work faster</h3>
      <p className='text-[#252525] font-normal text-[14px] lg:text-[18px] md:w-[63%] text-center my-[20px] relative z-[1]'>An app that simplifies workflows with managing tasks and communication in one seamless platform.</p>
      <button className='w-[150px] md:w-[235px] h-[45px] md:h-[55px] rounded-[12px] bg-gradient-to-bl from-[#FDD01F] via-[#F8C240] to-[#F8861B] relative z-[1] text-[#241501] font-semibold text-[16] md:text-[20px]' onClick={(e) => { handleBookClick(e) }}>Join Us</button>
    </div>
  )
}
