import React from 'react'
import { ArrowSvg } from './svg';




export default function Comparative() {

    const [currentIndex, setCurrentIndex] = React.useState(1);
    const sliderRef = React.useRef(null);

    const [position, setPosition] = React.useState(window.innerWidth <= 645);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 645);
        };
    }, []);

    const slides = [
        { image: 'https://dz1x1c630cl14.cloudfront.net/webassets/Aimockup.webp', title: "AI", description: "Workfast.ai is most efficient for this one reason . Artificial Intelligence which is built over your companies data will provide basic to difficult suggestions whenever you need it . For example if you ask for projects which is completed above 80%, it will give the results . if you ask for a particular persons performance in a projects it will give it no time. similarly , leaves, Approvals, project decisions are very easy to manage.", description1: "" },
        { image: `https://dz1x1c630cl14.cloudfront.net/webassets/Automationmock.webp`, title: "Automation", description: "Workfast.ai is most efficient for this one reason . Artificial Intelligence which is built over your companies data will provide basic to difficult suggestions whenever you need it . For example if you ask for projects which is completed above 80%, it will give the results . if you ask for a particular persons performance in a projects it will give it no time. similarly , leaves, Approvals, project decisions are very easy to manage.", description1: "" },
        { image: `https://dz1x1c630cl14.cloudfront.net/webassets/intergration.webp`, title: "Integration", description: "While working in Workfast.ai it is very simple to integrate any software tools which is much needed to your present work . For example , you can integrate , Notion, slack , Asana, Jira and the list extends. It makes work easier and does not interrupt the work flow ." },
        { image: `https://dz1x1c630cl14.cloudfront.net/webassets/channel&chat.webp`, title: "Channel and Chat", description: "This features will help users to communicate professionally on certain projects or tasks . The user can create numerous channels for communication , share images , videos , links , documents and send voice note", description1: "While chatting , the user can change the message into tasks and assign it to any members without going out the channel or chat. This helps in evading miscommunication and increase the efficiency of assigning tasks " },
        { image: `https://dz1x1c630cl14.cloudfront.net/webassets/task.webp`, title: "Task and project management", description: "Every projects are divided into multiple tasks . one can create projects and assign as many team members they want. once done , the user can see the improvements in the dashboard . Similarly , the projects can be given status, tags and priority for easy understanding. Once the tasks are getting complete , the dashboard shows weekly analytics , project owner details and project description etc.", description1: "one can create their own workflow for the project . when needed the team members can message and comment on the project itself." },
        { image: `https://dz1x1c630cl14.cloudfront.net/webassets/mac&phone.webp`, title: "Mobile and web view", description: "Giving same experience as web view to mobile view is a difficult task. Workfast.ai accommodates the same experience as web thus giving relief to people who don't like to carry laptops to travelling places. This helps people who are managers , VPʼs, CEOʼs , CXOʼs and people who are freelancers and WFH employees.", description1: "" },
    ]

    // Add clones for infinite effect
    const extendedSlides = [slides[slides.length - 1], ...slides, slides[0]];

    // React.useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex(prev => prev + 1);
    //     }, 5000); // Adjust slide transition time here

    //     return () => clearInterval(interval);
    // }, []);

    React.useEffect(() => {
        // Reset position for seamless loop
        if (currentIndex === extendedSlides.length - 1) {
            setTimeout(() => setCurrentIndex(1), 0);
        } else if (currentIndex === 0) {
            setTimeout(() => setCurrentIndex(extendedSlides.length - 2), 0);
        }
    }, [currentIndex, extendedSlides.length]);
    return (
        <div className='flex flex-col justify-center items-center w-10/12 relative z-[1] mx-auto mb-[20px] sm:mb-[60px] xl:mb-[100px]'>
            <h2 className='text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] text-[#000000] font-bold relative z-[1] sm:w-10/12 lg:w-9/12'>How Workfast.ai is most efficient software tool comparatively ?</h2>
            <div className="overflow-hidden relative w-full">
                <div ref={sliderRef} className="flex transition-transform duration-700 ease-in-out relative" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {extendedSlides.map((each, index) => (
                        <div key={index} className="flex-shrink-0 w-full focus:outline-none relative z-[10]">
                            <div className="flex flex-col items-center justify-center">
                                <img src={each?.image} alt={each?.title} className="md:w-[80%]" loading="lazy" />
                                <div className="w-[80%] mx-auto mt-[30px] md:mt-[60px] lg:mt-[100px]">
                                    <h2 className={`text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] text-[#000000] font-bold ${index === 1 ? "w-[12%] md:w-[5%] xl:w-[4%]" : index === 2 || index === 3 ? "w-[60%] md:w-[30%] xl:w-[25%]" : index === 4 ? "w-[70%] md:w-[50%] xl:w-[38%]" : index === 5 ? "w-full md:w-[80%] lg:w-[70%] xl:w-[62%]" : "w-full md:w-[60%] lg:w-[50%] xl:w-[43%]"}`}>{each?.title}</h2>
                                    <p className="text-[#252525] font-normal text-[12px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[20px]">{each?.description}</p>
                                    {each?.description1 && (
                                        <p className="text-[#252525] font-normal text-[12px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[10px]">{each?.description1}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='absolute z-[20] top-[35%] sm:top-1/2 -translate-x-1/2 right-[50px] sm:right-[120px] cursor-pointer' onClick={() => { setCurrentIndex(prev => prev - 1) }} >
                    <ArrowSvg size={position ? "22" : "44"}/>
                </div>
                <div className='absolute z-[20] top-[35%] sm:top-1/2 -translate-x-1/2 right-[20px] sm:right-[50px] rotate-180 cursor-pointer' onClick={() => { setCurrentIndex(prev => prev + 1) }} >
                    <ArrowSvg size={position ? "22" : "44"}/>
                </div>
            </div>
            <div className='w-9/12 mx-auto h-[1px] rounded-sm mt-[50px]' style={{ background: 'radial-gradient(6488.24% 804% at -355.73% -23.71%, #18599F 0%, rgba(53, 45, 10, 0) 100%)' }}></div>
            <p className='text-[#252525] font-normal text-[12px] md:text-[16px] lg:text-[20px] xl:text-[26px] mt-[40px] text-center w-10/12'>As we conclude , it is not about the tool rather it is about the efficiency which delivers best for the product development and management. Workfast.ai gives the best all-in features where one does not to find a replacement. Since product managers need to connect all dots , it is very important for them to find a workable software to work productively.</p>
        </div>
    )
}