import React from 'react'
import { BuildSpanSvg } from '../svg';


export default function Modules() {
    const [features, setFeatures] = React.useState("Current");
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1]'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-start sm:text-center relative z-[1]'>Modules in Workfast.ai’s?</h3>
                <div className='absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[30px] sm:-left-[50px] lg:-left-[30px] z-0'>
                    <BuildSpanSvg colour="#30B0C7" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <div className='p-[3px] sm:p-[6px] bg-[#00193D1A] rounded-[6px] sm:rounded-[12px] w-full sm:w-auto flex flex-row items-center mt-[22px]'>
                <button className={`${features === "Current" ? "bg-[#3C84F3] text-[#FFFFFF]" : "bg-transparent text-[#000409C7]"} transition-all duration-300 w-[120px] sm:w-[265px] h-[41px] rounded-[4px] sm:rounded-[9px] text-[11px] sm:text-[17px] font-semibold basis-1/2`} onClick={() => { setFeatures("Current") }}>Current Features</button>
                <button className={`${features === "Upcoming" ? "bg-[#3C84F3] text-[#FFFFFF]" : "bg-transparent text-[#000409C7]"} transition-all duration-300 w-[120px] sm:w-[265px] h-[41px] rounded-[4px] sm:rounded-[9px] text-[11px] sm:text-[17px] font-semibold basis-1/2`} onClick={() => { setFeatures("Upcoming") }}>Upcoming Features</button>
            </div>
            <div className='relative mt-[22px] sm:mt-[32px]'>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/confidential/background.png" alt='bg' className='rounded-[15px]' loading='lazy' />
                <img src={`${features === "Current" ? "https://dz1x1c630cl14.cloudfront.net/webassets/images/confidential/current.webp" : "https://dz1x1c630cl14.cloudfront.net/webassets/images/confidential/upcoming.webp"}`} alt='features' loading='lazy' className='absolute top-1/2 -translate-y-1/2 w-[80%] left-1/2 -translate-x-1/2' />
            </div>
        </div>
    )
}
