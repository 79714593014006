import React from 'react'

export default function Desktop() {
  const backgroundRef = React.useRef(null);
  const [backgroundHeight, setBackgroundHeight] = React.useState(null);

  const handleImageLoad = () => {
    if (backgroundRef.current) {
      setBackgroundHeight(backgroundRef.current.clientHeight);
    }
  };
  return (
    <div className='relative w-full z-[21] top-[80px]'>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/userguide-background.svg" ref={backgroundRef}  alt="onboarding" loading='lazy' className='absolute z-0 w-full' style={{boxShadow : '2px 0px 4px 0px #00000040'}} onLoad={handleImageLoad}/>
        <div className='xl:py-[36px] w-9/12 mx-auto relative z-[1] flex justify-center flex-col' style={{height: `${backgroundHeight}px`}}>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/userguide-icon.svg" alt='workfast' loading='lazy' className='w-[170px] lg:w-[260px] h-[26px] lg:h-[36px]' width={260} height={36}/>
            <h1 className='text-[#000000] text-[32px] lg:text-[40px] xl:text-[58px] font-bold xl:mt-[5px]'>User Guide</h1>
            <h2 className='text-[#000000] text-[14px] lg:text-[18px] font-medium xl:mt-[5px]'>Onboarding</h2>
        </div>
    </div>
  )
}
