import React from 'react'
import { AddToTaskSvg, CopyMessageSvg, DeleteMessageSvg, EditMessageSvg, ForwardMessageSvg, MarkUnreadSvg, PinToChannelSvg, RemindMeSvg, SaveForLaterSvg } from '../../../svg';

export default function MessageActions() {
  return (
    <div className='flex flex-col gap-[11px] justify-center'>
      <h3 className='text-[#000000] text-[18px] font-bold'>Message Actions</h3>
      <video src="https://dz1x1c630cl14.cloudfront.net/webassets/userguide/Channel/channel15.mov" autoPlay playsInline loop muted className='rounded-[12px]'></video>
      <div className='mt-[6px]'>
        <p className='text-[#000000] text-[14px] font-medium'>Enhance your channel experience with message actions like "Reply," "Forward," "Edit," and "Delete" and more. Access these options by clicking on the message's action menu. These features help you manage and interact with messages efficiently. Use message actions to keep your channel organized and responsive!</p>
        <div className='mt-[11px] flex flex-col gap-[11px]'>
          <div className='flex gap-[8px] items-start'>
            <div className='flex shrink-0'>
              <PinToChannelSvg size="20" />
            </div>
            <p className='text-[#000000] text-[14px] font-semibold'><span className='text-[#FFA800] font-bold'>Pin to Channel</span> : Pin to Channel highlights key messages for quick access.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='flex shrink-0'>
              <EditMessageSvg size="20" />
            </div>
            <p className='text-[#000000] text-[14px] font-semibold'><span className='text-[#00A4FF] font-bold'> Edit Message</span> : Edit Message lets you update or correct sent messages.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='flex shrink-0'>
              <DeleteMessageSvg size="20" />
            </div>
            <p className='text-[#000000] text-[14px] font-semibold'><span className='text-[#FF4949] font-bold'>Delete Message</span> : Delete Message removes a sent message permanently.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='flex shrink-0'>
              <ForwardMessageSvg size="20" />
            </div>
            <p className='text-[#000000] text-[14px] font-semibold'><span className='text-[#D482FF] font-bold'>Forward</span> : Forward shares a message with another chat or channel.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='flex shrink-0'>
              <SaveForLaterSvg size="20" />
            </div>
            <p className='text-[#000000] text-[14px] font-semibold'><span className='text-[#00C7BE] font-bold'>Save for Later </span> : Save for Later bookmarks messages for future reference.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='flex shrink-0'>
              <AddToTaskSvg size="20" />
            </div>
            <p className='text-[#000000] text-[14px] font-semibold'><span className='text-[#27AE60] font-bold'>Add to Task</span> : Add to Task turns a message into a task for tracking.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='flex shrink-0'>
              <MarkUnreadSvg size="20" />
            </div>
            <p className='text-[#000000] text-[14px] font-semibold'><span className='text-[#FD4438] font-bold'>Mark Unread</span> : Mark Unread sets a message as unread to revisit later.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='flex shrink-0'>
              <RemindMeSvg size="20" />
            </div>
            <p className='text-[#000000] text-[14px] font-semibold'><span className='text-[#8B96FF] font-bold'>Remind Me</span> : Remind Me sets a message reminder for later.</p>
          </div>
          <div className='flex gap-[8px] items-start'>
            <div className='flex shrink-0'>
              <CopyMessageSvg size="20" />
            </div>
            <p className='text-[#000000] text-[14px] font-semibold'><span className='text-[#6CBFFF] font-bold'>Copy Message</span> : Copy Message duplicates the message text to your clipboard.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
