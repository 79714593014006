import React from 'react'


export default function Trusted() {
  return (
    <div className='flex flex-col gap-[20px] sm:gap-[32px] items-center justify-center w-full mt-[20px] sm:mt-[60px] lg:mt-[90px] px-[20px] sm:px-0 relative z-[15]'>
        <p className='text-[#000000] text-[24px] sm:text-[32px] lg:text-[47px] font-bold text-center'>Our Trusted Customers</p>
        <div className='flex flex-col sm:flex-row flex-wrap gap-[43px] items-center justify-center'>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/tie-chennai.webp" alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/one-dot.webp" alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/vista.webp" alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/adept.webp" alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/naturals.webp" alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/rajalakshmi-institute.webp" alt='workfast' loading='lazy' className='w-[50%] sm:w-auto' width={300} height={300}/>
        </div>
    </div>
  )
}
