import React from 'react'
import axios from 'axios';
import API from '../../../../utils/API';
import { IoCloseSharp } from 'react-icons/io5';
import { BiSolidUserAccount } from "react-icons/bi";
import { GoAlert } from "react-icons/go";
import LoadLottie from '../../loadLottie';
import sucess from "../../../../json/sucess.json";
import { CompanySvg, ContactSvg, EmailSvg, ProfileSvg } from './svg';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';

export default function BookingForm() {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [phoneNumberError, setPhoneNumberError] = React.useState(false);
    const [companyName, setCompanyName] = React.useState('');
    const [peopleCount, setPeopleCount] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [isSucess, setIsSucess] = React.useState(false);
    const [position, setPosition] = React.useState(window.innerWidth <= 645);


    const handleNameChange = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            setName(e.target.value)
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        const email = e.target.value;
        const gmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === "") {
            setEmailError(false);
        }
        else if (!gmailRegex.test(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }

    const handleCompanyNameChange = (e) => {
        if (/^[\x20-\x7E]*$/.test(e.target.value)) {
            setCompanyName(e.target.value);
        }
    }

    const handlePhoneNumberChange = (phone, country) => {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const dialCode = country?.country?.dialCode || "";
        const phoneWithoutPlus = phone.startsWith(`+${dialCode}`) ? phone.slice(dialCode.length + 1) : phone;
        let isValid = false;
        try {
            const parsedPhone = phoneUtil.parse(phone, country?.country?.iso2);
            isValid = phoneUtil.isValidNumber(parsedPhone);
        } catch (error) {
            isValid = false;
        }
        if (!isValid || phoneWithoutPlus === "") {
            setPhoneNumberError(true)
        } else {
            setPhoneNumberError(false)
        }
        setPhoneNumber(phone);
    }

    const handlePeopleCountChange = (e) => {
        const peopleCounts = e.target.value.replace(/\D/g, '');
        if (peopleCounts !== '' && parseInt(peopleCounts, 10) > 0) {
            setPeopleCount(peopleCounts);
        } else {
            setPeopleCount('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const { data } = await axios.post(API.DETAILS,
            {
                name: name,
                email: email,
                phone: phoneNumber,
                company_name: companyName,
                people_count: peopleCount
            });
        const response = await axios.post(API.HOST, { email: email });
        if (response.data) {
            console.log("success")
        }
        // const createTask = await axios.post(API.CREATE_TASK,
        //     {
        //         projectId: 5866,
        //         title: "ask karthick which field in title",
        //         description: "ask karthick which field in description ",
        //         createdUser: 25578854,
        //         assignee: [45154152],
        //     },
        //     {
        //         headers: {
        //             form: "bookdemo",
        //         },
        //     });
        // console.log(createTask);
        if (data?.statusCode === 200) {
            setName('');
            setEmail('');
            setPhoneNumber('');
            setCompanyName('');
            setPeopleCount('');
            setIsSucess(true);
        }
        setLoading(false)
    };

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 645);
        };
    }, []);

    return (
        <div>
            <div className="flex justify-center items-center text-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full bg-[#121825] bg-opacity-60 z-[100]">
                <div className='w-[300px] md:w-[630px] bg-[#FFF] rounded-[16px] p-[20px] sm:p-[32px] relative z-[10]'>
                    <div className='grid md:grid-cols-2 sm:gap-x-5 gap-y-8 sm:gap-y-10 items-center mt-[15px] md:mt-[30px] w-full'>
                        <div className='w-full'>
                            <div className='flex items-center gap-1'>
                                <ProfileSvg size={position ? "19" : "23"} />
                                <h3 className='text-[#252525] text-[12px] md:text-[14px] font-medium'>Name*</h3>
                            </div>
                            <div className={`w-full h-[38px] md:h-[44px] bg-[#fff] border-[1px] border-[CECEEA] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                                <input type='text' className='w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%]' value={name} placeholder='Enter your name' onChange={(e) => { handleNameChange(e); }} />
                            </div>
                        </div>
                        <div className='w-full relative'>
                            <div className='flex items-center gap-1'>
                                <EmailSvg size={position ? "19" : "23"} />
                                <h3 className='text-[#252525] text-[12px] md:text-[14px] font-medium'>Email*</h3>
                            </div>
                            <div className={`w-full h-[38px] md:h-[44px] border-[1px] border-[CECEEA] lg:py-[10px] mt-[10px] rounded-[8px] ${emailError ? "border-[1px] border-[#F92A4B]" : ""}`}>
                                <input type='email' className='w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%]' placeholder='Enter your email' value={email} onChange={(e) => { handleEmailChange(e) }} />
                            </div>
                            {emailError && (
                                <div className='absolute flex gap-[10px] items-center text-[12px] sm:text-[14px] text-red-700 font-medium -bottom-[23px] sm:-bottom-[28px]'>
                                    <GoAlert />
                                    <div>Please Enter Your Work Email</div>
                                </div>
                            )}
                        </div>
                        <div className='w-full'>
                            <div className='flex items-center gap-1'>
                                <ContactSvg size={position ? "19" : "23"} />
                                <h3 className='text-[#252525] text-[12px] md:text-[14px] font-medium'>Phone Number*</h3>
                            </div>
                            <div className={`w-full h-[38px] md:h-[44px] border-[1px] border-[CECEEA] lg:py-[10px] mt-[10px] rounded-[8px] ${phoneNumberError ? "border-[1px] border-[#F92A4B]" : ""}`}>
                                {/* <input type='text' className='w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%]' placeholder='Enter your Phone number' value={phoneNumber} onChange={(e) => { handlePhoneNumberChange(e) }} /> */}
                                <PhoneInput defaultCountry="in" value={phoneNumber} onChange={(phone, country) => { handlePhoneNumberChange(phone, country) }} className="w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%] mx-auto booking" />
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='flex items-center gap-1'>
                                <CompanySvg size={position ? "19" : "23"} />
                                <h3 className='text-[#252525] text-[12px] md:text-[14px] font-medium'>Company name*</h3>
                            </div>
                            <div className={`w-full h-[38px] md:h-[44px] border-[1px] border-[CECEEA] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                                <input type='text' className='w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%]' placeholder='Enter your Company name' value={companyName} onChange={(e) => { handleCompanyNameChange(e) }} />
                            </div>
                        </div>
                    </div>
                    <div className='w-full mt-8 sm:mt-[30px]'>
                        <div className='flex items-center gap-1'>
                            <BiSolidUserAccount className='text-[#27AE60]' />
                            <h3 className='text-[#252525] text-[12px] md:text-[14px] font-medium'>No of People in your company*</h3>
                        </div>
                        <div className={`w-full h-[38px] md:h-[44px] border-[1px] border-[CECEEA] lg:py-[10px] mt-[10px] rounded-[8px]`}>
                            <input type='text' className='w-full text-[#252525] placeholder:text-[#252525]/[40%] h-full text-[12px] lg:text-[16px] placeholder:text-[12px] lg:placeholder:text-[16px] bg-transparent focus:outline-none max-w-[90%]' placeholder='Enter Number of People in your company' value={peopleCount} onChange={(e) => { handlePeopleCountChange(e) }} />
                        </div>
                    </div>
                    {loading ?
                        <div class="loader"></div>
                        :
                        <button className={`w-full h-[38px] md:h-[44px] rounded-[8px] border-[1px] border-[#995900] bg-[#FDD01F] text-[#2A2200] text-[16px] font-medium mt-[30px] ${name === "" || email === "" || emailError || phoneNumber === "" || phoneNumberError || companyName === "" || peopleCount === '' ? "opacity-30" : ""}`} disabled={name === "" || email === "" || emailError || phoneNumber === "" || phoneNumberError || companyName === "" || peopleCount === ''} onClick={(e) => { handleSubmit(e) }}>Book a Demo</button>
                    }
                </div>
            </div>
            {isSucess && (
                <div className="flex justify-center items-center text-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full bg-[#121825] bg-opacity-60 z-[100]">
                    <div className='w-[300px] md:w-[630px] md:h-[200px] bg-[#FFF] rounded-[16px] p-[32px] relative z-[10]'>
                        <div
                            className="absolute top-0 right-0 md:top-[20px] md:right-[20px] cursor-pointer border-[#000] p-2 rounded-lg"
                            onClick={() => {
                                window.location.href = "/"
                            }}
                        >
                            <IoCloseSharp className='text-[#000] w-[20px] h-[20px] md:w-[30px] md:h-[30px]' />
                        </div>
                        <div className='w-full flex  justify-center'>
                            <div className="w-[100px] flex justify-center">
                                <LoadLottie animationData={sucess} loop={true} />
                            </div>
                        </div>
                        <h3 className='text-[14px] font-medium text-[#000] '>Thank you for your interest! Our team will be reaching out to you shortly.</h3>
                    </div>
                </div>
            )}
        </div>
    )
}
