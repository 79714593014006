import React from 'react'

export default function ChatToTask() {
    return (
        <div className='flex flex-col gap-[15px] lg:gap-[18px] xl:gap-[22px]'>
            <h2 className='text-[#000000] text-[21px] md:text-[26px] lg:text-[30px] font-semibold relative z-[1] w-[135px] md:w-[170px] lg:w-[190px]'>Chat to Task :</h2>
            <p className='text-[#252525] text-[15px] md:text-[18px] lg:text-[20px] font-normal'>I have forgotten many important tasks which was discussed in a meeting as it is a Human nature. But it will cost you dearer in your credibility. many tasks have been lost between 1000 chats. So what to do ? How can we be smart in that? Workfast.ai will have a nicer option. wherever you chat be it be in channels, or whatsapp or any platform which you have been inytegrated with Workfast.ai, you can instantly add the message as a task from the chat. It will be shown in the task section. It will notify who assigned you the task, due date and time. No more loosing valuable information. One can assign tasks after the team meeting. Immediately after the Minutes of meeting is noted, one can assign it as a task to the respective team members. This is as simple as blinking your eyes.</p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/blog/chat-to-task.webp" alt='chatToTaskImg' loading="lazy"/>
        </div>
    )
}
