import React from 'react'
import { TbCircleCheckFilled, TbCircleMinus } from 'react-icons/tb';
import { SmartsheetsIconSvg, WorkfastSvg } from '../../../svg';
import API from '../../../../../../utils/API';
import axios from 'axios';



export default function Comparison() {
    const list = [
        { title: 'Comprehensive Dashboard for all teams', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Gannt chart', smartsheets: 'YES', workfast: 'YES' },
        { title: 'Kanban View', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Agile Workflow', smartsheets: 'NO', workfast: 'YES' },
        { title: '20+ Workflow templates', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Complete Project Management', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Complete Task management', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Project view', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Board View Priority View', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Date / Calendar View', smartsheets: 'NO', workfast: 'YES' },
        { title: 'People / Profile View', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Stage View', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Group view', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Box view', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Reports and Analytics ( Graph and CSV)', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Advanced Automation', smartsheets: 'YES', workfast: 'YES' },
        { title: 'AI intelligence integration', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Assigned tasks from comments', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Recurring Tasks', smartsheets: 'YES', workfast: 'YES' },
        { title: 'Remainder Tasks', smartsheets: 'YES', workfast: 'YES' },
        { title: 'Workflow Automation and easy customisation', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Duplication of Projects , Tasks , Documents', smartsheets: 'NO', workfast: 'YES' },
        { title: '100+ Software Integrations', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Task Checklist', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Sticky Notes', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Public and Private Channel', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Direct Messages and Group Messages', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Documents with defined folders and embedded files options including spreadsheets.', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Attachments within projects , Tasks , Documents .', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Invites and Collaboration with Access control', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Dynamic Recurring Tasks', smartsheets: 'NO', workfast: 'YES' },
        { title: 'Goals-Projects-Tasks-Sub Tasks', smartsheets: 'NO', workfast: 'YES' },
    ]
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const handleBookClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "BOOK_FOR_DEMO_COMPARISON_SMARTSHEETS"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };

    return (
        <div className='flex flex-col justify-center items-center gap-[32px] w-11/12 sm:w-10/12 mx-auto'>
            <div className='flex flex-col justify-center items-center gap-[12px]'>
                <h3 className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center xl:w-11/12 '>Power your projects by simplified planning and workflow with workfast.ai than </h3>
                <div className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center flex flex-wrap gap-[8px] sm:gap-[12px] items-center justify-center w-11/12 sm:w-auto'>
                    <div className='w-[25px] sm:w-[35px] lg:w-[50px] xl:w-[55px]'>
                        <SmartsheetsIconSvg size={position >= 1440 ? "55" : position >= 1024 ? "50" : position >= 645 ? "35" : "25"} />
                    </div>
                    <h3>Smartsheets</h3>
                </div>
            </div>
            <div className='w-full'>
                <div className='grid grid-cols-4 border-[1px] border-b-[#CECEEA] rounded-t-[6px] sm:rounded-t-[12px] w-full'>
                    <div className='col-span-2 px-[10px] py-[14px] sm:px-[18px] sm:py-[25px]'>
                        <div className='text-[#514B81] text-[12px] sm:text-[16px] font-bold'>Compare features</div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#F002500D] w-full h-full flex flex-col sm:flex-row justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <SmartsheetsIconSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] sm:text-[14px] lg:text-[16px] font-bold'>Smartsheets</div>
                        </div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#4CB9390D] w-full h-full flex flex-col sm:flex-row  justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <WorkfastSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] ssm:text-[14px] lg:text-[16px] font-bold'>Workfast.ai</div>
                        </div>
                    </div>
                </div>
                {list?.map((each, index) => (
                    <div key={index} className={`grid grid-cols-4 border-b-[1px] border-x-[1px] border-[#CECEEA4D] w-full ${list.length - 1 === index ? "rounded-b-[12px]" : ""}`}>
                        <div className='col-span-2 p-[10px] sm:p-[20px]'>
                            <div className='text-[#24223E] font-semibold text-[10px] sm:text-[15px]'>{each.title}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.smartsheets === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.smartsheets === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.workfast === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.workfast === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-[40px] sm:mt-[100px] flex flex-col justify-center items-center gap-[20px] sm:gap-[40px]'>
                <h3 className='text-[#24223E] text-[22px] sm:text-[30px] lg:text-[42px] font-semibold sm:w-10/12 text-center'>Gift Your team with focus and time with Workfast.ai</h3>
                <button className='w-[170px] h-[40px] lg:w-[270px] lg:h-[64px] rounded-[11px] bg-gradient-to-br from-[#FDD01F] via-[#FDD320] to-[#F8861B] text-[#2A2200] text-[16px] lg:text-[21px] font-semibold cursor-pointer' onClick={(e) => { handleBookClick(e) }}>Try it Free</button>
            </div>
        </div>
    )
}
