import React from 'react'
import { BuildSpanSvg } from '../svg';

export default function Usp() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1]'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>What is Workfast.ai’s USP?</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[30px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#00C7BE" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <p className='text-[#333333] text-[19px] sm:text-[20px] lg:text-[26px] leading-[33px] sm:leading-[36px] lg:leading-[42px] font-medium w-full sm:w-10/12 xl:w-[60%] text-start sm:text-center mt-[8px]'>Workfast.ai offers an all-in-one productivity platform that seamlessly integrates project management, task tracking, team communication, and collaboration. With AI-driven automation, scalable AI agents, and customizable workflows, it empowers businesses to boost efficiency, reduce costs, and improve team performance</p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/confidential/usp.webp" alt='banner' className='mt-[35px] sm:w-[50%]' loading='lazy'/>
        </div>
    )
}
