import React from 'react'
import { WorkfastIconSvg, BlueArrowSvg, JiraSvg } from '../../../svg';
import API from '../../../../../../utils/API';
import axios from 'axios';


export default function Price() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const handleBookClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "BOOK_FOR_DEMO_COMPARISON_JIRA"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };

    return (
        <div className='flex flex-col justify-center items-center gap-[30px] sm:gap-[64px] px-[20px] sm:px-0' id='prices'>
            <div className='flex justify-center items-center flex-col gap-[34px] relative z-[1]'>
                <div className='flex justify-center items-center'>
                    <div className='w-[100px] sm:w-[150px] lg:w-auto'>
                        <WorkfastIconSvg size={position >= 1024 ? "192" : position >= 645 ? "150" : "100"} />
                    </div>
                    <BlueArrowSvg />
                    <div className='w-[50px] sm:w-[80px] lg:w-auto'>
                        <JiraSvg size={position >= 1024 ? "125" : position >= 645 ? "80" : "50"} />
                    </div>
                </div>
                <h3 className='text-[#000000] text-[16px] sm:text-[22px] lg:text-[28px] xl:text-[36px] font-semibold text-center sm:w-11/12 lg:w-10/12 relative z-[1]'>One app to replace Jira for communication and task management</h3>
            </div>
            <div className='flex flex-col gap-[18px] sm:gap-[30px] items-center justify-center sm:w-11/12 mx-auto'>
                <h3 className='text-[#24223E] font-semibold text-[22px] sm:text-[30px] lg:text-[40px] xl:text-[50px] text-center'>Use Workfast.ai to Work faster and Smarter</h3>
                <div className='flex flex-col gap-[18px] items-center justify-center'>
                    <h4 className='text-[#383838] font-medium text-[16px] sm:text-[20px] lg:text-[30px] text-center'>New era business needs New tools for Communication </h4>
                    <div className='bg-gradient-to-l from-[#FDD01F] via-[#FDD320] to-[#F8861B] rounded-[11px] px-[34px] py-[6px] sm:py-[10px] lg:py-[17px] font-semibold text-[#2A2200] text-[14px] sm:text-[18px] lg:text-[21px] flex justify-center items-center cursor-pointer' onClick={(e) => { handleBookClick(e) }}>Try it Free</div>
                </div>
            </div>
        </div>
    )
}