import React from 'react'
import { BuildSpanSvg } from '../svg';

export default function Investors() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1]'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Our Investors</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[30px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#FFCC00" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <p className='text-[#333333] text-[19px] sm:text-[20px] lg:text-[26px] leading-[33px] sm:leading-[36px] lg:leading-[42px] font-medium w-full sm:w-10/12 xl:w-[60%] text-start sm:text-center mt-[8px]'>Private Family Office (South India) – Pre-Series A Lead with $ 3.3 Million Hourglass Venture Partners (Silicon Valley) – Seed Round Lead</p>
            <div className='sm:w-11/12 xl:w-10/12 mx-auto grid grid-cols-1 sm:flex justify-between items-center'>
                <div className='flex flex-col gap-[12px] items-center'>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/investors/guns.webp" alt='guns' loading='lazy' className='sm:size-[300px] object-cover' />
                    <h2 className='text-[#FDBC37] text-[20px] lg:text-[26px] font-semibold text-center'>Gans Subramaniam</h2>
                    <div className='text-[#060606] text-[18px] lg:text-[25px] font-medium text-center'>
                        <h2>Founder & Managing Partner</h2>
                        <h2>Hourglass Venture Partners</h2>
                    </div>
                </div>
                <div className='flex flex-col gap-[12px] items-center'>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/investors/girish.webp" alt='girish' loading='lazy' className='sm:size-[300px] object-cover' />
                    <h2 className='text-[#F4A4D7] text-[20px] lg:text-[26px] font-semibold text-center'>Girish Mathrubootham</h2>
                    <div className='text-[#060606] text-[18px] lg:text-[25px] font-medium text-center'>
                        <h2>CEO & Founder of</h2>
                        <h2>Freshworks</h2>
                    </div>
                </div>
                <div className='flex flex-col gap-[12px] items-center'>
                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/investors/vijay.webp" alt='paytm' loading='lazy' className='sm:size-[300px] object-cover' />
                    <h2 className='text-[#1ABC9C] text-[20px] lg:text-[26px] font-semibold text-center'>Vijay Shekhar Sharma</h2>
                    <div className='text-[#060606] text-[18px] lg:text-[25px] font-medium text-center'>
                        <h2>Founder & CEO of</h2>
                        <h2>Paytm</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}