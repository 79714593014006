import React from 'react'
import { WorkfastLogo } from '../../../header/svg';

export default function Help() {
    const handleEmailClick = () => {
        window.open('https://mail.google.com/mail/?view=cm&fs=1&to=support@workfast.ai');
    };
    const [position, setPosition] = React.useState(window.innerWidth <= 1024);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 1024);
        };
    }, []);
    return (
        <div className='flex flex-col w-full h-screen justify-center items-center bg-[#FFF] relative overflow-hidden p-[10px] md:p-[0px]'>
            {/* <h1 className='DarkerGrostesqueSemibold text-[30px] sm:text-[40px] lg:text-[80px] bg-gradient-to-l from-[#FDD220] to-[#FFFFFF] bg-clip-text text-transparent text-center z-[1]'>Help</h1> */}
            <div className='relative z-[10]'>
                <WorkfastLogo width={position ? "200" : "300"} height={position ? "28" : "53"} />
            </div>
            <h3 className='text-[#000] font-medium text-[12px] sm:text-[14px] lg:text-[28px] mt-[30px] lg:mt-[50px] xl:w-[80%] text-center relative z-[10]'>For any queries and support needed, reach us at <span className='text-[#FDD220] underline cursor-pointer' onClick={() => { handleEmailClick() }}>support@workfast.ai</span>.</h3>
            <h3 className='text-[#000] font-medium text-[12px] sm:text-[14px] lg:text-[28px] mt-[16px] w-[80%] text-center'>Our team will respond and do the needful.</h3>

        </div>
    )
}
