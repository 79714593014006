import React from 'react'
import Header from '../../../header'
import Footer from '../../../footer'
import Banner from './banner'
import Form from './form'
import Trusted from './trusted'
import HowItWork from './how_it_work'
import Feedback from './feedback'


export default function GoogleLeads() {

    return (
        <div className='relative overflow-hidden'>
            <Header />
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/website-background.png" alt='background' className='absolute z-[0] w-full top-0' loading='lazy'/>
            <Banner/>
            <Form/>
            <Trusted/>
            <HowItWork/>
            <Feedback/>
            <Footer/>
        </div>
    )
}
