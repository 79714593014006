import React from 'react'
import API from '../../../../../utils/API';
import axios from 'axios';

export default function Desktop() {

  const handleBookClick = async (e) => {
    e.preventDefault();
    const response = await axios.post(API.BUTTON,
        {
            "eventName": "BOOK_FOR_DEMO_HOME"
        }
    );
    if (response.data.statusCode === 200) {
        window.location.href = '/book-for-demo';
    }
};


  return (
    <div className='mt-[40px] lg:mt-[65px] flex flex-col gap-[40px] lg:gap-[60px] xl:gap-[90px] justify-center items-center mx-auto relative z-[15]'>
        <div className='flex flex-col items-center justify-center text-center gap-[16px] lg:gap-[24px]'>
            <h1 className='text-[#180833] text-[32px] lg:text-[40px] xl:text-[50px] font-bold px-[30px] lg:px-[60px]'>Workfast helps you to get the most valuable <br/> thing in the world, "Your Time".</h1>
            <h2 className='text-[#180833] text-[18px] lg:text-[22px] font-medium w-9/12'>Workfast.ai is designed and developed to save your time by bringing in <br/> Focus and Productivity.</h2>
            <button className='bg-[#FDD01F] rounded-[8px] px-[34px] lg:px-[46px] py-[12px] text-[#181000] text-[14px] lg:text-[16px] font-semibold' onClick={(e) => {handleBookClick(e)}}>Book a Demo</button>
        </div>
        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/home/banner-project.webp"  alt='workfast' loading='lazy' className='w-10/12' width={400} height={400}/>
    </div>
  )
}
