import React from 'react'
import { FaRegClock } from 'react-icons/fa6'
import { ArticlesSvg } from '../svg';


export default function Articles() {
    const [position, setPosition] = React.useState(window.innerWidth <= 1024);
    
    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 1024);
        };
    }, []);
    return (
        <div className='flex flex-col gap-[32px] xl:gap-[48px] px-[20px] sm:px-0 w-full md:w-10/12 relative z-[1] mx-auto'>
            <div className='flex gap-[14px] items-center'>
                <div className='w-[35px] lg:w-[40px]'>
                    <ArticlesSvg size={position ? "35" : "45"}/>
                </div>
                <h3 className='text-[#000000] font-semibold text-[21px]'>Articles</h3>
            </div>
            <div className='flex flex-col md:flex-row gap-[20px] lg:gap-[28px] overflow-x-scroll justify-between w-full element-with-scrollbar'>
                <a title='blog' href='/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai'>
                    <div className='rounded-[8px] sm:max-w-[300px] lg:max-w-[378px] flex flex-col shrink-0 cursor-pointer bg-[#F8F8FC] border-[#CECEEA border-[2px]'>
                        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/blog/article-2.webp" alt='workfast' loading="lazy" className='rounded-t-[8px]'/>
                        <div className='px-[18px] py-[14px] rounded-b-[8px] flex flex-col gap-[10px] lg:gap-[14px]'>
                            <h2 className='text-[#000000] text-[18px] lg:text-[24px] font-semibold'>Breaking Barriers in Communication and Collaboration with Workfast.ai</h2>
                            <div className='flex justify-between items-center'>
                                <div className='flex gap-[4px] items-center'>
                                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/blog/peter.webp" alt='communication app' loading="lazy" className='w-[25px] lg:w-[35px] rounded-full' />
                                    <h2 className='text-[#1C1C1C] text-[15px] lg:text-[17px] font-medium'>Peter Elk</h2>
                                </div>
                                <div className='flex gap-[4px] items-center'>
                                    <FaRegClock className='text-[#1C274C] text-[15px] lg:text-[17px] font-medium' />
                                    <h2 className='text-[#1C1C1C] text-[15px] lg:text-[17px] font-medium'>18 min read</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
                <a title='blog' href='/blog/top-7-ways-workfast.ai-can-revolutionise-your-project-management'>
                    <div className='rounded-[8px] sm:max-w-[300px] lg:max-w-[378px] flex flex-col shrink-0 cursor-pointer bg-[#F8F8FC] border-[#CECEEA border-[2px]'>
                        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/blog/article-3.webp" alt='articles' loading="lazy" className='rounded-t-[8px]'/>
                        <div className='px-[20px] py-[14px] rounded-b-[8px] flex flex-col gap-[10px] lg:gap-[14px]'>
                            <h2 className='text-[#000000] text-[18px] lg:text-[24px] font-semibold'>Top 7 ways Workfast.ai can Revolutionise your project management</h2>
                            <div className='flex justify-between items-center'>
                                <div className='flex gap-[4px] items-center'>
                                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/blog/peter.webp" alt='workfast' loading="lazy" className='w-[25px] lg:w-[35px] rounded-full' />
                                    <h2 className='text-[#1C1C1C] text-[15px] lg:text-[17px] font-medium'>Peter Elk</h2>
                                </div>
                                <div className='flex gap-[4px] items-center'>
                                    <FaRegClock className='text-[#1C274C] text-[15px] lg:text-[17px] font-medium' />
                                    <h2 className='text-[#1C1C1C] text-[15px] lg:text-[17px] font-medium'>18 min read</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
                <a title='blog' href='/blog/looking-for-a-new-platform-for-project-management'>
                    <div className='rounded-[8px] sm:max-w-[300px] lg:max-w-[378px] flex flex-col shrink-0 cursor-pointer bg-[#F8F8FC] border-[#CECEEA border-[2px]'>
                        <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/blog/article-4.webp" alt='workfast' loading="lazy" className='rounded-t-[8px]'/>
                        <div className='px-[20px] py-[14px] rounded-b-[8px] flex flex-col gap-[10px] lg:gap-[14px]'>
                            <h2 className='text-[#000000] text-[18px] lg:text-[24px] font-semibold'>Looking for a new platform for project management ? Discover Workfast.ai</h2>
                            <div className='flex justify-between items-center'>
                                <div className='flex gap-[4px] items-center'>
                                    <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/blog/peter.webp" alt='workfast' loading="lazy" className='w-[25px] lg:w-[35px] rounded-full' />
                                    <h2 className='text-[#1C1C1C] text-[15px] lg:text-[17px] font-medium'>Peter Elk</h2>
                                </div>
                                <div className='flex gap-[4px] items-center'>
                                    <FaRegClock className='text-[#1C274C] text-[15px] lg:text-[17px] font-medium' />
                                    <h2 className='text-[#1C1C1C] text-[15px] lg:text-[17px] font-medium'>18 min read</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}
