import React from 'react'
import { TbCircleCheckFilled, TbCircleMinus } from 'react-icons/tb';
import { AsanaIconSvg, WorkfastSvg } from '../../../svg';
import API from '../../../../../../utils/API';
import axios from 'axios';



export default function Comparison() {
    const list = [
        { title: 'Multiple assignee in Channel, Chat, Projects, Tasks, Docs', asana: 'NO', workfast: 'YES' },
        { title: 'Add to Tasks from Chat and Channel', asana: 'NO', workfast: 'YES' },
        { title: 'Assigned Comments to Task', asana: 'NO', workfast: 'YES' },
        { title: '100+ Integrations', asana: 'YES', workfast: 'YES' },
        { title: '20+ templates', asana: 'NO', workfast: 'YES' },
        { title: 'Custom Roles', asana: 'NO', workfast: 'YES' },
        { title: 'Native Docs', asana: 'NO', workfast: 'YES' },
        { title: '24/7 free support', asana: 'NO', workfast: 'YES' },
        { title: 'Feedback Channel for bug report and feature updates.', asana: 'NO', workfast: 'YES' },
        { title: 'Voting Channel for group idea', asana: 'NO', workfast: 'YES' },
        { title: 'Check-in Check-out', asana: 'NO', workfast: 'YES' },
        { title: 'Box view', asana: 'NO', workfast: 'YES' },
        { title: '7+ common view', asana: 'NO', workfast: 'YES' },
        { title: 'Collaborators and invites with Access restrictions in Channel, Chat, Projects, Tasks, Docs', asana: 'NO', workfast: 'YES' },
        { title: 'Manage all projects, tasks and Docs from Mobile with same experience as desktop', asana: 'NO', workfast: 'YES' },
        { title: 'Public and Private Channel', asana: 'NO', workfast: 'YES' },
        { title: 'Direct and Group Messages', asana: 'NO', workfast: 'YES' },
        { title: 'Downloadable Task and Project reports', asana: 'NO', workfast: 'YES' },
        { title: 'Recurring and remainders', asana: 'YES', workfast: 'YES' },
        { title: 'Advanced automation', asana: 'YES', workfast: 'YES' },
        { title: 'People and Organisation chart with Profile', asana: 'NO', workfast: 'YES' },
        { title: 'Universal search', asana: 'YES', workfast: 'YES' },
        { title: 'Save files with the projects and tasks', asana: 'NO', workfast: 'YES' },
        { title: 'Set Titles, Description, colour theme', asana: 'NO', workfast: 'YES' },
    ]
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const handleBookClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "BOOK_FOR_DEMO_COMPARISON_ASANA"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };
    return (
        <div className='flex flex-col justify-center items-center gap-[32px] w-11/12 sm:w-10/12 mx-auto'>
            <h3 className='text-[#000000] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold sm:w-10/12 lg:w-9/12 text-center'>Workfast.ai has lot more features for half the price</h3>
            <h4 className='text-[#8E8E93] text-[14px] sm:text-[18px] lg:text-[20px] xl:text-[24px] font-normal w-10/12 lg:w-9/12 text-center'>Workfast.ai with simple and elegant UI/UX makes it a best choice for teams to organise task and communicate faster</h4>
            <div>
                <div className='grid grid-cols-4 border-[1px] border-b-[#CECEEA] rounded-t-[6px] sm:rounded-t-[12px] w-full'>
                    <div className='col-span-2 px-[10px] py-[14px] sm:px-[18px] sm:py-[25px]'>
                        <div className='text-[#514B81] text-[12px] sm:text-[16px] font-bold'>Compare features</div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#F002500D] w-full h-full flex flex-col sm:flex-row justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <AsanaIconSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] sm:text-[14px] lg:text-[16px] font-bold'>Asana</div>
                        </div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#4CB9390D] w-full h-full flex flex-col sm:flex-row  justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <WorkfastSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] ssm:text-[14px] lg:text-[16px] font-bold'>Workfast.ai</div>
                        </div>
                    </div>
                </div>
                {list?.map((each, index) => (
                    <div key={index} className={`grid grid-cols-4 border-b-[1px] border-x-[1px] border-[#CECEEA4D] w-full ${list.length - 1 === index ? "rounded-b-[12px]" : ""}`}>
                        <div className='col-span-2 p-[10px] sm:p-[20px]'>
                            <div className='text-[#24223E] font-semibold text-[10px] sm:text-[15px]'>{each.title}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.asana === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.asana === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.workfast === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.workfast === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-[40px] sm:mt-[100px] flex flex-col justify-center items-center gap-[20px] sm:gap-[40px]'>
                <h3 className='text-[#24223E] text-[22px] sm:text-[30px] lg:text-[42px] font-semibold sm:w-10/12 text-center'>Get Twice the value for half the price with Workfast.ai</h3>
                <button className='w-[170px] h-[40px] lg:w-[270px] lg:h-[64px] rounded-[11px] bg-gradient-to-br from-[#FDD01F] via-[#FDD320] to-[#F8861B] text-[#2A2200] text-[16px] lg:text-[21px] font-semibold cursor-pointer' onClick={(e) => { handleBookClick(e) }}>Try it Free</button>
            </div>
        </div>
    )
}
