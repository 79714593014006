import React from 'react'
import { Link } from 'react-scroll';
import InvitePeople from './invite-people';
import InviteTeamMember from './invite-team-member';
import GuestCustomerInvitations from './guest-customer-invitation';
import SetExpirationDate from './set-expirations';
import PendingInviteManagement from './pending-invite';
import AcceptedInviteDetails from './accept-invite';

export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Invite People', id: 'invite-people' },
        { title: 'Invite Team Members via Email', id: 'invite-team-members-via-email' },
        { title: 'Pending Invite Management', id: 'pending-invite-management' },
        { title: 'Accepted Invite Details', id: 'accepted-invite-details' },
        { title: 'Guest & Customer Invitations', id: 'guest-customer-invitations' },
        { title: 'Set Expiration Date for Guest & Customer Invites', id: 'set-expiration-date-for-guest-customer-invites' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[100px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[120px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>Welcome to People Module in which you can invite team members to collaborate in your workspace using email or a shared link, and assign them roles such as member, admin, guest, or customer. Additionally, you can track the status of invitations by checking for pending or accepted invites.</p>
                </div>
                <div id='invite-people'><InvitePeople /></div>
                <div id='invite-team-members-via-email'><InviteTeamMember/></div>
                <div id='pending-invite-management'><PendingInviteManagement/></div>
                <div id='accepted-invite-details'><AcceptedInviteDetails/></div>
                <div id='guest-customer-invitations'><GuestCustomerInvitations/></div>
                <div id='set-expiration-date-for-guest-customer-invites'><SetExpirationDate/></div>
            </div>
        </div>
    )
}
