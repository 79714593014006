import React from 'react'
import { BuildSpanSvg } from '../svg'

export default function BehindWorkfast() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1]'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Who is Behind Workfast.ai?</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[30px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#FFCC00" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <p className='text-[#333333] text-[19px] sm:text-[20px] lg:text-[26px] leading-[33px] sm:leading-[36px] lg:leading-[42px] font-medium w-full sm:w-11/12 xl:w-[71%] text-start sm:text-center mt-[8px]'>Workfast.ai is built by a passionate team led by our Founder and CEO, Suresh Kumar G, recognized as one of Intel’s World’s Most Valuable Developers. He has been awarded Top Innovator of 2016 & 2017 and previously bootstrapped a million-dollar company. Suresh also launched Pepul - A Positive Social Media. He also earned the prestigious Intel Black Belt title, The highest recognition for developers worldwide.</p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/confidential/behind-workfast.webp" alt='banner' className='mt-[35px] sm:w-[50%]' loading='lazy'/>
        </div>
    )
}
