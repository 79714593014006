import React from 'react'
import { TbCircleCheckFilled, TbCircleMinus } from 'react-icons/tb';
import { TrelloIconSvg, WorkfastSvg } from '../../../svg';
import API from '../../../../../../utils/API';
import axios from 'axios';



export default function Comparison() {
    const list = [
        { title: 'Private and Public Channel', trello: 'NO', workfast: 'YES' },
        { title: 'Direct messages and Group chats', trello: 'NO', workfast: 'YES' },
        { title: 'Voting Channel', trello: 'NO', workfast: 'YES' },
        { title: 'Feed Back channel', trello: 'NO', workfast: 'YES' },
        { title: 'Add to task from chat and channel', trello: 'NO', workfast: 'YES' },
        { title: 'Check-in Check-out', trello: 'NO', workfast: 'YES' },
        { title: 'Project view', trello: 'YES', workfast: 'YES' },
        { title: 'Board View', trello: 'YES', workfast: 'YES' },
        { title: 'Priority view', trello: 'NO', workfast: 'YES' },
        { title: 'Date view', trello: 'NO', workfast: 'YES' },
        { title: 'People View', trello: 'NO', workfast: 'YES' },
        { title: 'Stage view', trello: 'YES', workfast: 'YES' },
        { title: 'Group view', trello: 'NO', workfast: 'YES' },
        { title: 'Kanban view', trello: 'YES', workfast: 'YES' },
        { title: 'Gannt view', trello: 'NO', workfast: 'YES' },
        { title: 'Box view', trello: 'NO', workfast: 'YES' },
        { title: 'Dashboard view', trello: 'NO', workfast: 'YES' },
        { title: 'Task status, tags, priority', trello: 'NO', workfast: 'YES' },
        { title: 'Due date setting and recurring tasks', trello: 'NO', workfast: 'YES' },
        { title: 'Duplicate task and projects', trello: 'NO', workfast: 'YES' },
        { title: 'Task management', trello: 'YES', workfast: 'YES' },
        { title: 'Project management', trello: 'NO', workfast: 'YES' },
        { title: 'Customisation & Integration', trello: 'YES', workfast: 'YES' },
        { title: 'File Sharing', trello: 'YES', workfast: 'YES' },
        { title: 'Comment within Tasks and Projects', trello: 'NO', workfast: 'YES' },
        { title: 'Save files within the projects and tasks', trello: 'NO', workfast: 'YES' },
        { title: 'Project and Task info in one place', trello: 'NO', workfast: 'YES' },
        { title: 'Universal search', trello: 'NO', workfast: 'YES' },
        { title: 'Automation', trello: 'YES', workfast: 'YES' },
        { title: 'Invite and Collaborate', trello: 'NO', workfast: 'YES' },
        { title: 'Attach files by Drag and Drop', trello: 'NO', workfast: 'YES' },
        { title: 'Activity metrics and Analytics', trello: 'NO', workfast: 'YES' },
        { title: 'Performance monitoring', trello: 'NO', workfast: 'YES' },
        { title: '15+ Filters and sort by option', trello: 'NO', workfast: 'YES' },
        { title: 'Report generation - daily, weekly, monthly, Quarterly, yearly', trello: 'NO', workfast: 'YES' },
        { title: 'Downloadable reports and analytics', trello: 'NO', workfast: 'YES' },
        { title: 'Tasks and subtasks', trello: 'NO', workfast: 'YES' },
        { title: 'People and profile management', trello: 'NO', workfast: 'YES' },
        { title: 'Privacy settings', trello: 'NO', workfast: 'YES' },
        { title: 'Calendar integration', trello: 'YES', workfast: 'YES' },
        { title: 'Customised workflow', trello: 'YES', workfast: 'YES' },
        { title: 'Both mobile and desktop apps', trello: 'YES', workfast: 'YES' },
        { title: 'Set Titles, Description, colour theme', trello: 'YES', workfast: 'YES' },
        { title: 'Document sharing and storage', trello: 'YES', workfast: 'YES' },
        { title: '100+ templates', trello: 'NO', workfast: 'YES' },
    ]
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const handleBookClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "BOOK_FOR_DEMO_COMPARISON_TRELLO"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };
    
    return (
        <div className='flex flex-col justify-center items-center gap-[32px] w-11/12 sm:w-10/12 mx-auto'>
            <div className='flex flex-col justify-center items-center gap-[12px]'>
                <div className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[62px] font-semibold text-center flex flex-wrap gap-[8px] sm:gap-[12px] items-center justify-center w-11/12 sm:w-auto'>
                    <div className='w-[15px] sm:w-auto'>
                        <TrelloIconSvg size={position >= 1440 ? "55" : position >= 1024 ? "50" : position >= 645 ? "35" : "25"} />
                    </div>
                    <h3>Trello  is simple, workfast.ai is smart</h3>
                </div>
                <h4 className='text-[#8E8E93] text-[14px] sm:text-[18px] lg:text-[20px] xl:text-[24px] font-normal w-10/12 lg:w-7/12 text-center'>Get better choice and option with Workfast.ai for any industry workflows</h4>
            </div>
            <div className='w-full'>
                <div className='grid grid-cols-4 border-[1px] border-b-[#CECEEA] rounded-t-[6px] sm:rounded-t-[12px] w-full'>
                    <div className='col-span-2 px-[10px] py-[14px] sm:px-[18px] sm:py-[25px]'>
                        <div className='text-[#514B81] text-[12px] sm:text-[16px] font-bold'>Compare features</div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#F002500D] w-full h-full flex flex-col sm:flex-row justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <TrelloIconSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] sm:text-[14px] lg:text-[16px] font-bold'>Trello</div>
                        </div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#4CB9390D] w-full h-full flex flex-col sm:flex-row  justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <WorkfastSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] ssm:text-[14px] lg:text-[16px] font-bold'>Workfast.ai</div>
                        </div>
                    </div>
                </div>
                {list?.map((each, index) => (
                    <div key={index} className={`grid grid-cols-4 border-b-[1px] border-x-[1px] border-[#CECEEA4D] w-full ${list.length - 1 === index ? "rounded-b-[12px]" : ""}`}>
                        <div className='col-span-2 p-[10px] sm:p-[20px]'>
                            <div className='text-[#24223E] font-semibold text-[10px] sm:text-[15px]'>{each.title}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.trello === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.trello === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.workfast === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.workfast === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-[40px] sm:mt-[100px] flex flex-col justify-center items-center gap-[20px] sm:gap-[40px]'>
                <h3 className='text-[#24223E] text-[22px] sm:text-[30px] lg:text-[42px] font-semibold sm:w-10/12 text-center'>Get Twice the value for half the price with Workfast.ai</h3>
                <button className='w-[170px] h-[40px] lg:w-[270px] lg:h-[64px] rounded-[11px] bg-gradient-to-br from-[#FDD01F] via-[#FDD320] to-[#F8861B] text-[#2A2200] text-[16px] lg:text-[21px] font-semibold cursor-pointer' onClick={(e) => { handleBookClick(e) }}>Try it Free</button>
            </div>
        </div>
    )
}
