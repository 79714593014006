import React from 'react'
import { MouseSvg } from '../../svg';

export default function Banner() {
  
  const handleHomeClick = () => {
    const element = document.getElementById('principles');
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 80,
        behavior: 'smooth'
      });
    }
  };

  const [position, setPosition] = React.useState(window.innerWidth <= 645);
      
      React.useEffect(() => {
          window.onresize = function () {
              setPosition(window.innerWidth <= 645);
          };
      }, []);

  return (
    <div className='flex flex-col justify-center items-center bg-white relative mt-[20px] lg:mt-[60px]'>
      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/reseller/handshake.webp" alt='workfast' loading='lazy' className='relative z-[1] mt-[60px] lg:mt-[40px]'/>
      <h3 className='text-[#000] text-[32px] sm:text-[52px] lg:text-[72px] font-bold relative z-[1]'>Workfast.ai</h3>
      <h3 className='text-[#000] text-[32px] sm:text-[52px] lg:text-[72px] font-bold relative z-[1]'>Partnership Program</h3>
      <h4 className='text-[#000] text-[14px] sm:text-[20px] lg:text-[24px] font-normal text-center w-10/12 lg:w-8/12 mx-auto relative z-[1]'>This program aims at providing greater opportunities for experienced individuals and consultants for earning fortunes by leveraging their networks and connections </h4>
      <div className='mt-[20px] sm:mt-[40px] lg:mt-[60px] cursor-pointer up-arrow relative z-[1]' onClick={() =>{handleHomeClick()}}>
        <MouseSvg width={position ? "42" : "82"} height={position ? "82" : "122"}/>
      </div>
      <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/website-background.png" alt='background' loading='lazy' className='absolute z-[0] w-full -top-[60px]' />
    </div>
  )
}
