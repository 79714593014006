import React from 'react'
import { LogoSvg } from '../../blog/svg'
import { TiInfoLarge } from "react-icons/ti";
import { Helmet } from 'react-helmet';


export default function SuccessPage() {
    return (
        <div className='w-full h-screen flex justify-center items-center px-[20px] sm:px-0'>
            <Helmet>
                {/* <!-- Google tag (gtag.js) --> */}
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-01QSV2KX92"></script>
                <script>
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-01QSV2KX92');
                    `}
                </script>
            </Helmet>
            <div className='w-full sm:w-[550px] p-[20px] rounded-[20px] relative text-center' style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                <div className='z-[1] w-[60px] absolute rounded-full border-[1px] left-1/2 -translate-x-1/2 -top-[30px]'>
                    <LogoSvg size="60" />
                </div>
                <h1 className='text-[18px] sm:text-[24px] text-gray-500 font-semibold mt-[30px]'>Workfast.ai</h1>
                <h2 className='text-[16px] sm:text-[24px] text-[#000] font-bold mt-[10px]'>Congrats for taking your First Step</h2>
                <h3 className='text-[14px] sm:text-[20px] text-[#000] font-medium mt-[10px]'>Thanks for filling the Form. Our team will be <br className='hidden sm:block' /> reaching out to you shortly</h3>
                <div className='mt-[10px] pt-[10px] border-t border-gray-600 w-full flex gap-[10px] items-center justify-center text-[12px] sm:text-[20px] text-gray-600 font-semibold'>
                    <TiInfoLarge />
                    <h4>You successfully submitted your responses.</h4>
                </div>
            </div>
        </div>
    )
}
