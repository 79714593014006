import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";
import { AsanaSvg } from '../../../svg';
import API from '../../../../../../utils/API';
import axios from 'axios';

export default function Banner() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const handleBookClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "BOOK_FOR_DEMO_BLOG_COMPARISON_ASANA"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };
    return (
        <div className='mt-[20px] sm:mt-[60px] relative sm:top-[80px]'>
            <div className='px-[24px] py-[11px] w-[216px] mx-auto bg-[#F1F1F9] rounded-[120px] text-[#514B81] text-[14px] font-normal'>The #1 Asana Alternative</div>
            <div className='mt-[20px] lg:mt-[40px] flex flex-wrap sm:gap-[12px] justify-center items-center text-[#0A0800] text-[30px] sm:text-[40px] lg:text-[50px] xl:text-[62px] font-bold'>
                <h2>Best alternative to</h2>
                <div className='w-[30px] sm:w-[40px] xl:w-[50px] ml-[12px] sm:ml-0'>
                    <AsanaSvg size={position >= 1440 ? "50" : position >= 645 ? "40" : "30"} />
                </div>
                <h2>Asana for your</h2>
            </div>
            <h2 className='text-[#0A0800] text-[30px] sm:text-[40px] lg:text-[50px] xl:text-[62px] font-bold text-center'>team to work faster</h2>
            <h3 className='text-[#8E8E93] text-[14px] sm:text-[18px] lg:text-[20px] xl:text-[24px] font-normal text-center w-10/12 lg:w-8/12 mx-auto mt-[20px] lg:mt-[40px]'>Workfast.ai offers channel and chat space for teams to communicate effectively while managing projects and tasks efficiently</h3>
            <div className='bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] rounded-[12px] w-[200px] sm:w-[300px] lg:w-[420px] h-[40px] sm:h-[50px] lg:h-[66px] mx-auto mt-[20px] lg:mt-[40px] flex items-center justify-center sm:gap-[8px] text-[#2A2200] text-[16px] sm:text-[22px] lg:text-[28px] font-semibold cursor-pointer' onClick={(e) => { handleBookClick(e) }}>
                <h4>Book a Demo</h4><IoIosArrowRoundForward />
            </div>
        </div>
    )
}
