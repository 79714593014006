import React from 'react'
import { TbCircleCheckFilled, TbCircleMinus } from 'react-icons/tb';
import { SlackIconSvg, WorkfastSvg } from '../../../svg';
import API from '../../../../../../utils/API';
import axios from 'axios';



export default function Comparison() {
    const list = [
        { title: 'Channel', slack: 'YES', workfast: 'YES' },
        { title: 'Direct messages and Group chat', slack: 'YES', workfast: 'YES' },
        { title: 'File Sharing', slack: 'YES', workfast: 'YES' },
        { title: 'Universal search', slack: 'YES', workfast: 'YES' },
        { title: 'Easy Integration', slack: 'YES', workfast: 'YES' },
        { title: 'Mentions and Reactions', slack: 'YES', workfast: 'YES' },
        { title: 'Reminders', slack: 'YES', workfast: 'YES' },
        { title: 'Channel icons', slack: 'NO', workfast: 'YES' },
        { title: 'Channel restrictions', slack: 'YES', workfast: 'YES' },
        { title: 'Check-in & Check-out for team members.', slack: 'NO', workfast: 'YES' },
        { title: 'Add to Task from channel and chat', slack: 'NO', workfast: 'YES' },
        { title: 'Pin, Star, Save, Search in channel', slack: 'YES', workfast: 'YES' },
        { title: 'Reactions', slack: 'YES', workfast: 'YES' },
        { title: 'Live notifications and alerts by Mentions , Replies , Reactions', slack: 'YES', workfast: 'YES' },
        { title: 'Task and Sub Task management', slack: 'NO', workfast: 'YES' },
        { title: 'People and Profiles', slack: 'YES', workfast: 'YES' },
        { title: 'Feedback Channel', slack: 'NO', workfast: 'YES' },
        { title: 'Voting Channel', slack: 'NO', workfast: 'YES' },
        { title: 'Project management', slack: 'NO', workfast: 'YES' },
        { title: 'Analytics and Insights', slack: 'YES', workfast: 'YES' },
        { title: 'Multiple workspace', slack: 'YES', workfast: 'YES' },
        { title: 'Comments within Tasks and Projects', slack: 'NO', workfast: 'YES' },
        { title: 'Remainder and Automation of Tasks', slack: 'NO', workfast: 'YES' },
        { title: 'Recurring Tasks', slack: 'NO', workfast: 'YES' },
        { title: 'Status and Presence', slack: 'YES', workfast: 'YES' },
        { title: 'Mobile and Desktop apps', slack: 'YES', workfast: 'YES' },
        { title: 'Audio, camera, Document, Media sharing', slack: 'YES', workfast: 'YES' },
        { title: 'Customised workflow', slack: 'YES', workfast: 'YES' },
        { title: 'Shared channels', slack: 'YES', workfast: 'YES' },
        { title: 'Restrict  Access to invited members and collaborators', slack: 'NO', workfast: 'YES' },
        { title: 'Threads in Channel', slack: 'YES', workfast: 'YES' },
        { title: 'Import data from any platform to workfast.', slack: 'YES', workfast: 'YES' },
        { title: 'Export data to any platform from workfast anytime .', slack: 'NO', workfast: 'YES' },
        { title: 'Unlimited Message history', slack: 'NO', workfast: 'YES' },
        { title: 'Unlimited Task history and timeline', slack: 'NO', workfast: 'YES' },
    ]
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);

    const handleBookClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "BOOK_FOR_DEMO_COMPARISON_SLACK"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };

    return (
        <div className='flex flex-col justify-center items-center gap-[32px] w-11/12 sm:w-10/12 mx-auto'>
            <div className='flex flex-col justify-center items-center'>
                <h3 className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center'>Workfast.ai gives teams a more upper hand</h3>
                <div className='text-[#0A0800] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[52px] font-semibold text-center flex flex-wrap sm:gap-[12px] items-center justify-center w-11/12 sm:w-auto'>
                    <h3>in worklife balance than </h3>
                    <div className='w-[30px] sm:w-[40px] xl:w-auto ml-[12px] sm:ml-0'>
                        <SlackIconSvg size={position >= 1440 ? "68" : position >= 645 ? "40" : "30"} />
                    </div>
                    <h3>Slack </h3>
                </div>
            </div>
            <div className='w-full'>
                <div className='grid grid-cols-4 border-[1px] border-b-[#CECEEA] rounded-t-[6px] sm:rounded-t-[12px] w-full'>
                    <div className='col-span-2 px-[10px] py-[14px] sm:px-[18px] sm:py-[25px]'>
                        <div className='text-[#514B81] text-[12px] sm:text-[16px] font-bold'>Compare features</div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#F002500D] w-full h-full flex flex-col sm:flex-row justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <SlackIconSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] sm:text-[14px] lg:text-[16px] font-bold'>Slack</div>
                        </div>
                    </div>
                    <div className='border-l-[1px] border-[#CECEEA] p-[2px] sm:p-[5px]'>
                        <div className='bg-[#4CB9390D] w-full h-full flex flex-col sm:flex-row  justify-center items-center sm:gap-[6px]'>
                            <div className='w-[15px] sm:w-auto'>
                                <WorkfastSvg size={position >= 645 ? "25" : "15"} />
                            </div>
                            <div className='text-[#24223E] text-[10px] ssm:text-[14px] lg:text-[16px] font-bold'>Workfast.ai</div>
                        </div>
                    </div>
                </div>
                {list?.map((each, index) => (
                    <div key={index} className={`grid grid-cols-4 border-b-[1px] border-x-[1px] border-[#CECEEA4D] w-full ${list.length - 1 === index ? "rounded-b-[12px]" : ""}`}>
                        <div className='col-span-2 p-[10px] sm:p-[20px]'>
                            <div className='text-[#24223E] font-semibold text-[10px] sm:text-[15px]'>{each.title}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.slack === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.slack === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                        <div className='border-l-[1px] border-[#CECEEA4D] p-[2px] sm:p-[5px]'>
                            <div className={`flex justify-center items-center w-full h-full ${each.workfast === "YES" ? "bg-[#4CB9390D]" : "bg-[#F002500D]"}`}>{each.workfast === "YES" ? <TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#4CB939]' /> : <TbCircleMinus TbCircleCheckFilled className='size-[14px] sm:size-[28px] text-[#F00250]' />}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mt-[40px] sm:mt-[100px] flex flex-col justify-center items-center gap-[20px] sm:gap-[40px]'>
                <h3 className='text-[#24223E] text-[22px] sm:text-[30px] lg:text-[42px] font-semibold sm:w-10/12 text-center'>Ready to try the ultimate tool for productivity and management ?</h3>
                <button className='w-[170px] h-[40px] lg:w-[270px] lg:h-[64px] rounded-[11px] bg-gradient-to-br from-[#FDD01F] via-[#FDD320] to-[#F8861B] text-[#2A2200] text-[16px] lg:text-[21px] font-semibold cursor-pointer' onClick={(e) => { handleBookClick(e) }}>Try it Free</button>
            </div>
        </div>
    )
}
