import React from 'react';
import Guide from './component/guide';
import ProductManagement from './component/productmanagement';
import Difference from './component/difference';
import Roles from './component/roles';
import ProductManager from './component/productmanager';
import RolesOfProductManager from './component/rolesofmanager';
import Tools from './component/tools';
import BestTools from './component/besttools';
import WorkFastAi from './component/workfatsai';
import Comparative from './component/comparative';
import Footer from '../../../footer';
import Banner from './component/banner';
import Header from '../header';

export default function Article1() {
    const [positions, setPositions] = React.useState(false);
    return (
        <div className={`w-full relative ${positions ? "overflow-hidden" : ""}`}>
            <Header setPositions={setPositions}/>
            <Banner/>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/website-background.png" alt='background' className='absolute z-[0] top-[20px] sm:top-[80px] w-full' />
            <Guide />
            <ProductManagement />
            <Difference />
            <Roles />
            <ProductManager />
            <RolesOfProductManager />
            <Tools />
            <BestTools />
            <WorkFastAi />
            <Comparative />
            <Footer />
        </div>
    )
}
