import React from 'react'
import { LogoSvg } from '../../../blog/svg'

export default function Banner() {
    const [position, setPosition] = React.useState(window.innerWidth <= 645);
    
      React.useEffect(() => {
        window.onresize = function () {
          setPosition(window.innerWidth <= 645);
        };
      }, []);

    return (
        <div className='mt-[40px] lg:mt-[65px] flex flex-col justify-center items-center gap-[16px] px-[20px] sm:px-0 relative z-[15] text-[#000000] text-[24px] sm:text-[34px] lg:text-[40px] xl:text-[60px] '>
            <div className='relative z-[1] w-[50px] sm:w-auto sm:mt-[40px] lg:mt-[70px] xl:mt-[100px]'>
                <LogoSvg size={position ? "50" : "90"} />
            </div>
            <h1 className='font-bold'>Workfast.ai</h1>
            <h2 className='font-semibold text-center'>Setup Your Business Process To Scale Next <br className='hidden sm:block'/> Level in 2025</h2>
        </div>
    )
}
