import React from 'react'
import { Link } from 'react-scroll';
import DirectMessaging from './direct-messaging';
import ProfileViewing from './profile-viewing';
import GroupChat from './group-chat';
import EditGroupInfo from './edit-group-info';
import AddParticipants from './add-participants';
import ChangeGroupIcon from './change-group-icon';
import ChatFunctions from './chat-functions';
import SendMedia from './send-media';
import MessageReactions from './message-reactions';
import RecordVoiceMessages from './record-voice-messages';
import ReplyMessages from './reply-messages';

export default function Content() {
    const [activeSection, setActiveSection] = React.useState(null);

    // Update active section on scroll
    const handleSetActive = (to) => {
        setActiveSection(to);
    };

    const tocData = [
        { title: 'Direct Messaging', id: 'direct-messaging' },
        { title: 'Profile Viewing', id: 'profile-viewing' },
        { title: 'Group Chat', id: 'group-chat' },
        { title: 'Edit Group Info', id: 'edit-group-info' },
        { title: 'Add Participants', id: 'add-participants' },
        { title: 'Change Group Icon', id: 'change-group-icon' },
        { title: 'Chat Functions', id: 'chat-functions' },
        { title: 'Send Media', id: 'send-media' },
        { title: 'Record Voice Messages', id: 'record-voice-messages' },
        { title: 'Message Reactions', id: 'message-reactions' },
        { title: 'Reply to Messages', id: 'reply-messages' },
    ];

    return (
        <div className='flex w-9/12 mx-auto gap-[63px] relative z-[21]'>
            <aside className='sticky top-[10px] right-0 self-start w-[35%] z-[21] rounded-[8px] block'>
                <ul className={`mt-[100px]`} data-hs-scrollspy="#scrollspy-2" data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2">
                    {tocData.map((section) => (
                        <li key={section.id}>
                            <Link
                                activeClass="" // Active link style
                                to={section.id}
                                spy={true}
                                smooth={true}
                                duration={100}
                                className={`flex gap-[10px] lg:gap-[17px] items-start py-[17px] cursor-pointer rounded-[6px] border-[#E6E9EF] border-b`}
                                onSetActive={handleSetActive}
                            >
                                <h3 className={`${activeSection === section.id ? "text-[#F6831D] text-[14px] lg:text-[18px] font-semibold" : "text-[#676879] text-[12px] lg:text-[16px] font-medium"}`}>{section.title}</h3>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
            <div className='flex flex-col gap-[22px] lg:gap-[32px] w-[65%] xl:w-[57%] relative top-[120px]'>
                <div className='text-[#000000] text-[15px] lg:text-[18px] font-normal'>
                    <p>In Workfast.ai, the chat feature is designed to facilitate real-time communication between team members. It allows you to exchange messages, share updates, and quickly resolve queries. Chats can be organized by channels or direct messages, giving you the flexibility to communicate with a single person or with multiple members at once.</p>
                </div>
                <div id='direct-messaging'><DirectMessaging /></div>
                <div id='profile-viewing'><ProfileViewing/></div>
                <div id='group-chat'><GroupChat/></div>
                <div id='edit-group-info'><EditGroupInfo/></div>
                <div id='add-participants'><AddParticipants/></div>
                <div id='change-group-icon'><ChangeGroupIcon/></div>
                <div id='chat-functions'><ChatFunctions /></div>
                <div id='send-media'><SendMedia/></div>
                <div id='record-voice-messages'><RecordVoiceMessages/></div>
                <div id='message-reactions'><MessageReactions/></div>
                <div id='reply-messages'><ReplyMessages/></div>
            </div>
        </div>
    )
}
