import React from 'react'
import { WorkfastLogo } from '../../../header/svg';

export default function ContactUs() {
    const handleEmailClick = () => {
        window.open('https://mail.google.com/mail/?view=cm&fs=1&to=hello@workfast.ai');
    };
    const [position, setPosition] = React.useState(window.innerWidth <= 1024);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth <= 1024);
        };
    }, []);
    return (
        <div className='flex flex-col w-full h-screen justify-center items-center relative overflow-hidden p-[10px] md:p-[0px]'>
            <div className='relative z-[10]'>
                <WorkfastLogo width={ position ? "200" : "300"} height={position ? "28" : "53"}/>
            </div>
            <h3 className='text-[#252525] font-medium text-[12px] sm:text-[14px] lg:text-[28px] mt-[30px] lg:mt-[50px] text-center relative z-[10]'>For contact, reach us at <span className='text-[#FDD220] underline cursor-pointer' onClick={() => { handleEmailClick() }}>hello@workfast.ai</span>.</h3>
            <h3 className='text-[#252525] font-medium text-[12px] sm:text-[14px] lg:text-[28px] mt-[16px] text-center'>Our team will respond and do the needful.</h3>

        </div>
    )
}
