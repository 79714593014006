import React from 'react'
import { BuildSpanSvg } from '../svg';


export default function FundingDetails() {
    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='flex flex-col justify-center items-center mt-[120px] px-[16px] sm:px-0 relative z-[1]'>
            <div className='relative'>
                <h3 className='text-[#180833] font-bold text-[24px] sm:text-[32px] lg:text-[42px] text-center relative z-[1]'>Funding Details</h3>
                <div className={`absolute top-[3px] sm:top-1/2 sm:-translate-y-1/2 -left-[30px] sm:-left-[30px] z-0`}>
                    <BuildSpanSvg colour="#AF52DE" width={position >= 1024 ? "262" : "182"} height={position >= 1024 ? "54" : position >= 645 ? "40" : "33"} />
                </div>
            </div>
            <div className='flex flex-col sm:flex-row flex-wrap justify-center items-center gap-[20px] sm:gap-[32px] lg:gap-[46px] mt-[42px]'>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/confidential/funding-details-1.webp" alt='one' className='flex shrink-0 w-[80%] sm:w-auto' loading='lazy'/>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/confidential/funding-details-2.webp" alt='two' className='flex shrink-0 w-[80%] sm:w-auto' loading='lazy'/>
                <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/confidential/funding-details-3.webp" alt='three' className='flex shrink-0 w-[80%] sm:w-auto' loading='lazy'/>
            </div>
        </div>
    )
}
