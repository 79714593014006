import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";
import API from '../../../../../../utils/API';
import axios from 'axios';

export default function Banner() {

  const handleBookClick = async (e) => {
    e.preventDefault();
    const response = await axios.post(API.BUTTON,
        {
            "eventName": "BOOK_FOR_DEMO_COMPARISON_SMARTSHEETS"
        }
    );
    if (response.data.statusCode === 200) {
        window.location.href = '/book-for-demo';
    }
};

  return (
    <div className='mt-[20px] sm:mt-[60px] relative sm:top-[80px]'>
        <div className='px-[24px] py-[11px] w-[272px] mx-auto bg-[#F1F1F9] rounded-[120px] text-[#514B81] text-[14px] font-normal text-center'>The #1 Smartsheet Alternative</div>
        <div className='mt-[20px] text-[#0A0800] text-[26px] sm:text-[40px] lg:text-[50px] xl:text-[62px] font-bold text-center w-10/12 mx-auto'>
            <h2>Get things done easier in All-In-One platform than with simple sheets</h2>
        </div>
        <h3 className='text-[#8E8E93] text-[14px] sm:text-[18px] lg:text-[20px] xl:text-[24px] font-normal text-center w-10/12 lg:w-[55%] mx-auto mt-[20px]'>Workfast.ai provides better options for integrations, customisation and collaboration to work smarter and faster</h3>
        <div className='bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] rounded-[12px] w-[200px] sm:w-[300px] lg:w-[420px] h-[40px] sm:h-[50px] lg:h-[66px] mx-auto mt-[20px] lg:mt-[40px] flex items-center justify-center sm:gap-[8px] text-[#2A2200] text-[16px] sm:text-[22px] lg:text-[28px] font-semibold cursor-pointer' onClick={(e) => {handleBookClick(e)}}>
            <h4>Book a Demo</h4><IoIosArrowRoundForward/>
        </div>
    </div>
  )
}