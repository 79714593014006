import React from 'react'
import { IoIosArrowRoundForward } from 'react-icons/io';
import { CheckImgSvg } from '../form/svg';

export default function HowItWork() {
    const [position, setPosition] = React.useState(window.innerWidth);

    const handleClick = () => {
        const element = document.getElementById("form");
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);
    return (
        <div className='mt-[60px] sm:mt-[100px] relative z-[15]'>
            <div className='w-11/12 bg-[#F0FBF8] p-[20px] sm:py-[65px] sm:px-[60px] lg:px-[100px] mx-auto rounded-[14px] sm:rounded-[60px] flex flex-col gap-[42px] items-center justify-center text-center'>
                {/* <h4 className='text-[#181F38] text-[18px] sm:text-[28px] lg:text-[52px] font-bold'>How It Works</h4> */}
                <div className='xl:w-8/12 mx-auto flex flex-col justify-center gap-[13px] sm:gap-[32px]'>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-center'>
                        <div className='relative size-[20px] sm:size-[50px] flex justify-start items-center shrink-0'>
                            <CheckImgSvg size={position >= 645 ? "50" : "20"} />
                        </div>
                        <div>
                            <p className='text-[#181F38] font-bold text-[11px] sm:text-[22px] lg:text-[28px]'>More revenue</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-center'>
                        <div className='relative size-[20px] sm:size-[50px] flex justify-start items-center shrink-0'>
                            <CheckImgSvg size={position >= 645 ? "50" : "20"} />
                        </div>
                        <div>
                            <p className='text-[#181F38] font-bold text-[11px]  sm:text-[22px] lg:text-[28px]'>More profits</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-center'>
                        <div className='relative size-[20px] sm:size-[50px] flex justify-start items-center shrink-0'>
                            <CheckImgSvg size={position >= 645 ? "50" : "20"} />
                        </div>
                        <div>
                            <p className='text-[#181F38] font-bold text-[11px]  sm:text-[22px] lg:text-[28px]'>More time & freedom for yourself</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px] sm:gap-[20px] lg:gap-[28px] items-center'>
                        <div className='relative size-[20px] sm:size-[50px] flex justify-start items-center shrink-0'>
                            <CheckImgSvg size={position >= 645 ? "50" : "20"} />
                        </div>
                        <div>
                            <p className='text-[#181F38] font-bold text-[11px] sm:text-[22px] lg:text-[28px]'>Setup Process & Frameworks Using Workfast.ai</p>
                        </div>
                    </div>
                </div>
                <p className='text-[#2B3857] text-[12px] sm:text-[22px] lg:text-[24px] font-normal'>Click on the Book now button,👇 and Join 1000's of Entrepreneurs Who Have Successfully Setup <br className='hidden xl:block' /> the Process and Scaled their Company to Next Level.</p>
                <div className={`w-[210px] h-[55px] rounded-full bg-[#FFCC00] mx-auto flex gap-[12px] justify-center items-center cursor-pointer border-[#FFEEAA] border-[4px] text-[#221B00] text-[16px] font-bold`} onClick={(e) => { handleClick() }}>
                    <div>Book now</div>
                    <IoIosArrowRoundForward />
                </div>
            </div>
        </div>
    )
}
