import React from 'react'

export default function HighRisk() {
    return (
        <div className="h-full w-full flex flex-col items-center relative mb-[20px] lg:mb-[60px] xl:mb-[100px] overflow-hidden px-[20px] sm:px-0">
            <h2 className='text-[24px]  sm:text-[40px] xl:text-[60px] text-[#060606] font-semibold text-center'>Workfast.ai is a high risk high return investments</h2>
            <p className='text-[#313131] text-[14px]  sm:text-[20px] xl:text-[25px] font-semibold sm:px-[40px] text-center sm:eading-10 mt-[10px]'>We don't take money from individuals. We will make investments only from seasoned entrepreneurs and professional VCs who have been through an entrepreneurial journey. This journey is very tough and it will have a lot of ups & downs which will not be able to be handled by people who have never gone through this before. Investing money in companies is a huge risk. Even though we have a clear plan and a great execution team, many factors matter. It's a 3 Year plan minimum and a long-term plan. The intention is to build a great company that will touch people's lives in a positive way and money will be a byproduct. We are aiming for a huge impact and returns as well.</p>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/investors/growth.webp" alt='GrowthUpIcon' loading='lazy' className='my-[40px] sm:my-[90px] sm:w-[30%]'/>
            <h2 className='text-[18px]  sm:text-[24px] xl:text-[35px] text-[#060606] font-semibold hidden sm:flex'>"We take money only from people who can align with our</h2>
            <h2 className='text-[18px]  sm:text-[24px] xl:text-[35px] text-[#060606] font-semibold hidden sm:flex'>audacious vision and hold for a long term"</h2>
            <h2 className='text-[18px] text-[#060606] font-semibold sm:hidden text-center'>"We take money only from people who can align with our audacious vision and hold for a long term"</h2>
        </div>
    )
}
