import React from 'react'
import API from '../../../../../utils/API';
import axios from 'axios';


export default function Banner() {
    const [isColour, setIsColour] = React.useState(0);
    const [isList, setIsList] = React.useState(0);
    const list = [
        { title: "Create task and set title, description, status, tags and priority." },
        { title: "Directly Comment on tasks so , your team knows better about it ." },
        { title: "See the timeline and history to get educated with the task for later." },
        { title: "Attach files and collect the necessary assets for the tasks" },
        { title: "See in different views to see who allocated what tasks ." },
    ];

    const scrollContainerRef = React.useRef(null);

    React.useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        const handleScroll = () => {
            if (scrollContainer.scrollLeft + scrollContainer.clientWidth >= scrollContainer.scrollWidth) {
                scrollContainer.scrollLeft = 0;
            }
        };
        scrollContainer.addEventListener('scroll', handleScroll);
        return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    React.useEffect(() => {
        const interval = setInterval(() => {
          setIsList((prevIsList) => (prevIsList + 1) % 5);
        }, 3000); 
    
        return () => clearInterval(interval);
    },[]);

    React.useEffect(() => {
        const interval = setInterval(() => {
          setIsColour((prevIsList) => (prevIsList + 1) % 3);
        }, 2000);
    
        return () => clearInterval(interval);
    },[]);

    const handleBookClick = async (e) => {
        e.preventDefault();
        const response = await axios.post(API.BUTTON,
            {
                "eventName": "BOOK_FOR_DEMO_INSIGHT_TASKMANAGEMENT"
            }
        );
        if (response.data.statusCode === 200) {
            window.location.href = '/book-for-demo';
        }
    };
    

    return (
        <div className='w-full sm:w-11/12 lg:w-10/12 flex flex-col justify-center items-center bg-[#FFF] mx-auto px-[20px] sm:px-0 sm:top-[100px] relative'>
            <div className='flex flex-col gap-[22px] justify-center items-center'>
                <h1 className='text-[24px] sm:text-[32px] lg:text-[50px] font-bold text-[#161000] text-center sm:w-[74%]'>Achieve more with an organised <span className={`${isColour === 0 ? "text-[#007AFF]" : isColour === 0 ?  "text-[#34C759]" : "text-[#AF52DE]"}`}>Task management</span> system</h1>
                <h3 className='text-[#161000] font-medium text-[14px] sm:text-[18px] lg:text-[24px] text-center xl:leading-[60px] w-full sm:w-[74%]'>Manage your projects by breaking down complex task into sub tasks and assign to right resource to get more output with workfast.ai</h3>
                <button className='text-[#2A2200] text-[14px] sm:text-[18px] lg:text-[21px] font-semibold px-[30px] py-[8px] sm:py-[14px] lg:px-[38px] lg:py-[18px] bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] rounded-[11px] relative z-[10] border border-[#FFF]' onClick={(e) => {handleBookClick(e)}}>Get Started</button>
            </div>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/insights/task-management.webp" alt='slack alternative' loading='lazy' className='sm:-top-[40px] relative' />
            <div className='mt-[46px] lg:mt-[66px] flex flex-col gap-[30px] sm:gap-[80px] lg:gap-[120px] justify-center items-center relative z-[10] w-10/12 overflow-hidden'>
                <div ref={scrollContainerRef} className='flex gap-[22px] items-center justify-center w-full'>
                    {[...list, ...list, ...list].map((item, index) => (
                        <div
                            key={index}
                            className={`w-[80%] mx-auto rounded-[24px] border h-[100px] sm:h-[151px] flex shrink-0 justify-center items-center text-center transition-transform duration-500 ${index % 5 === 0
                                    ? "border-[#FF3B30]"
                                    : index % 5 === 1
                                        ? "border-[#FF9500]"
                                        : index % 5 === 2
                                            ? "border-[#007AFF]" 
                                            : index % 5 === 3 ?
                                            "border-[#34C759]"
                                            : "border-[#00C7BE]" 
                                 } ${isList === 0 ? "translate-x-[218%] sm:translate-x-[210%] lg:translate-x-[205%]" : isList === 1 ? "translate-x-[109%] sm:translate-x-[103%]" : isList === 2 ? "translate-x-[0%]" : isList === 3 ? "-translate-x-[110%] sm:-translate-x-[103%]" : isList === 4 ? "-translate-x-[220%] sm:-translate-x-[205%]" :  ""}`}
                        >
                            <div className={`text-[#261900] font-semibold text-[14px] sm:text-[20px] lg:text-[24px] w-9/12 lg:w-8/12`}>{item?.title}</div>
                        </div>
                    ))}
                </div>
                <div className='flex flex-col gap-[18px] justify-center items-center'>
                    <h4 className='text-[#383838] text-[22px] sm:text-[32px] lg:text-[44px] font-semibold text-center'>Make complex work looks simpler to your team with Workfast.ai</h4>
                    <button className='text-[#2A2200] text-[14px] sm:text-[18px] lg:text-[21px] font-semibold px-[30px] py-[14px] lg:px-[38px] lg:py-[18px] bg-gradient-to-bl from-[#FDD01F] via-[#FDD320] to-[#F8861B] rounded-[11px] relative z-[10] border border-[#FFF]' onClick={(e) => {handleBookClick(e)}}>Try it Free</button>
                </div>
            </div>
        </div>
    )
}
