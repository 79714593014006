import React from 'react'
import { AndroidIconSvg, AppStoreSvg, AppleIconSvg, PlayStoreSvg, WebIconSvg, WindowsIconSvg } from '../svg';



export default function Availbles() {
    const [macHover, setMacHover] = React.useState(false)
    const [windowsHover, setWindowsHover] = React.useState(false)
    const [androidHover, setAndroidHover] = React.useState(false)
    const [IosHover, setIosHover] = React.useState(false)
    const [webHover, setWebHover] = React.useState(false)

    const [position, setPosition] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.onresize = function () {
            setPosition(window.innerWidth);
        };
    }, []);


    return (
        <div className='mb-[20px] sm:mb-[60px] lg:mb-[100px]'>
            <div className="flex flex-col gap-[20px] items-center relative w-10/12 mx-auto">
                <h3 className='text-[22px] sm:text-[38px] lg:text-[55px] font-semibold bg-gradient-to-l from-[#070707] to-[#070707] bg-clip-text text-transparent'>Available On</h3>
                <div className='absolute top-[18px] sm:top-[30px] lg:top-[40px] right-0 sm:right-[20px] w-[100px] xl:w-[340px] h-[40px] lg:h-[55px] hidden xl:block'>
                    <div className='w-full h-full animationBorder bg-[#999999] pt-[1px] pr-[1px] relative z-[1]  lg:rounded-tr-[50px]'>
                        <div className='w-full h-full bg-[#fff] relative z-[5] rounded-tr-[50px]'>
                        </div>
                    </div>
                </div>
                <div className='absolute top-[18px] sm:top-[30px] lg:top-[40px] left-0 sm:left-[20px] w-[100px] lg:w-[340px] h-[40px] lg:h-[55px] hidden xl:block'>
                    <div className='w-full h-full animationBorderLeft bg-[#999999] pt-[1px] pl-[1px] relative z-[1] rounded-tl-[50px]'>
                        <div className='w-full h-full bg-[#fff] relative z-[5] rounded-tl-[50px]'>
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap items-center justify-center xl:justify-between gap-[20px] sm:gap-[60px] mt-[20px] lg:mt-[50px] w-full relative z-10'>
                    <div className='flex gap-[15px] items-center' onMouseEnter={() => { setMacHover(true) }} onMouseLeave={() => { setMacHover(false) }}>
                        {macHover ? (
                            <div className='w-[30px] lg:w-[45px]'>
                                <AppleIconSvg colour="#000000" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        ) : (
                            <div className='w-[30px] lg:w-[45px]'>
                                <AppleIconSvg colour="#999999" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        )}
                        <h2 className='text-[#5F5F5F] font-semibold text-[18px] lg:text-[32px]'>Mac</h2>
                    </div>
                    <div className='flex gap-[15px] items-center' onMouseEnter={() => { setIosHover(true) }} onMouseLeave={() => { setIosHover(false) }}>
                        {IosHover ? (
                            <div className='w-[30px] lg:w-[45px]'>
                                <AppleIconSvg colour="#000000" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        ) : (
                            <div className='w-[30px] lg:w-[45px]'>
                                <AppleIconSvg colour="#999999" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        )}
                        <h2 className='text-[#5F5F5F] font-semibold text-[18px] lg:text-[32px]'>iOS</h2>
                    </div>
                    <div className='flex gap-[15px] items-center' onMouseEnter={() => { setAndroidHover(true) }} onMouseLeave={() => { setAndroidHover(false) }}>
                        {androidHover ? (
                            <div className='w-[30px] lg:w-[45px]'>
                                <AndroidIconSvg colour="#9CDB53" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        ) : (
                            <div className='w-[30px] lg:w-[45px]'>
                                <AndroidIconSvg colour="#999999" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        )}
                        <h2 className='text-[#5F5F5F] font-semibold text-[18px] lg:text-[32px]'>Android</h2>
                    </div>
                    <div className='flex gap-[15px] items-center' onMouseEnter={() => { setWindowsHover(true) }} onMouseLeave={() => { setWindowsHover(false) }}>
                        {windowsHover ? (
                            <div className='w-[30px] lg:w-[45px]'>
                                <WindowsIconSvg colour1="#67C947" colour2="#E54848" colour3="#F7CD48" colour4="#11A2EA" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        ) : (
                            <div className='w-[30px] lg:w-[45px]'>
                                <WindowsIconSvg colour1="#999999" colour2="#999999" colour3="#999999" colour4="#999999" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        )}
                        <h2 className='text-[#5F5F5F] font-semibold text-[18px] lg:text-[32px]'>Windows</h2>
                    </div>
                    <div className='flex gap-[15px] items-center' onMouseEnter={() => { setWebHover(true) }} onMouseLeave={() => { setWebHover(false) }}>
                        {webHover ? (
                            <div className='w-[30px] lg:w-[45px]'>
                                <WebIconSvg colour1="#6BB5FB" colour2="#4466FB" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        ) : (
                            <div className='w-[30px] lg:w-[45px]'>
                                <WebIconSvg colour1="#999999" colour2="#FFF" size={position >= 1024 ? "45" : "30"} />
                            </div>
                        )}
                        <h2 className='text-[#5F5F5F] font-semibold text-[18px] lg:text-[32px]'>Web</h2>
                    </div>
                </div>
            </div>
            <img src="https://dz1x1c630cl14.cloudfront.net/webassets/images/blog/avaialble-on.webp" alt='availble' loading='lazy' className='p-[20px] sm:w-[80%] relative z-[1] mx-auto mt-[40px]' />
            <div className='mt-[10px] lg:mt-[50px] relative z-[1] grid sm:flex md:flex-row justify-center items-center gap-[30px]'>
                <a title="Download on theApp Store" href="https://apps.apple.com/in/app/workfast-team-productivity/id6504078096" target="_blank" rel="noopener noreferrer">
                    <div className='px-[20px] lg:px-[30px] py-[10px] border-[1px] border-[#0A0D19] rounded-[14px] sm:rounded-[20px] flex gap-[20px] items-center cursor-pointer'>
                        <AppStoreSvg size={position >= 1024 ? "42" : position >= 645 ? "30" : "25"} />
                        <div className='flex flex-col justify-between'>
                            <h3 className='text-[#000000] text-[12px] sm:text-[16px] lg:text-[18px] font-medium'>Download on the</h3>
                            <h2 className='text-[#000000] text-[16px] sm:text-[22px] lg:text-[26px] font-semibold'>App Store</h2>
                        </div>
                    </div>
                </a>
                <a title="GET IT ONGoogle Play" href="https://play.google.com/store/apps/details?id=com.workfast.ai.app" target="_blank" rel="noopener noreferrer">
                    <div className='px-[20px] lg:px-[30px] py-[10px] border-[1px] border-[#0A0D19] rounded-[14px] sm:rounded-[20px] flex gap-[20px] items-center cursor-pointer'>
                        <PlayStoreSvg size={position >= 1024 ? "42" : position >= 645 ? "30" : "25"} />
                        <div className='flex flex-col justify-between'>
                            <h3 className='text-[#000000] text-[12px] sm:text-[16px] lg:text-[18px] font-medium'>GET IT ON</h3>
                            <h2 className='text-[#000000] text-[16px] sm:text-[22px] lg:text-[26px] font-semibold'>Google Play</h2>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}
